import React, { useState } from "react";
import packageJson from '../../package.json';

const FrontEndFooter = ({ tipo }) => {
  const appVersion = packageJson.version;


  return ( 

    <footer id="footer" class="footer">
      <div class="copyright">
        &copy; Copyright <strong><span>2023. INDUSTRIA BASICA S.A. - Grupo Casiba </span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        Calle 15 Nro 33 y Calle 9 - Parque Industrial Pilar, Pilar, Prov. de Buenos Aires, Argentina.<br />
        Tel: (+54-11) 4716-3777<br></br>
        <p className="version-text">Versión de la aplicación: {appVersion}</p>
      </div>
    </footer>

  );
}
export default FrontEndFooter;

