import React, { useState, useEffect } from "react";
import DownloadLink from './DownloadLink';

//const MenuSubCategorias = (props) => {
const FrontEndListasDeCatalogos = ({ tipo }) => {

    return (
        <>

            <br />
            <br />


            <div class="col-lg-6">
                <div class="card mb-3" style={{ height: "220px" }} >
                    <div class="row g-0">
                        <div class="col-md-2">
                            <img src="assets/img/catalogo_general.jpg" class="img-fluid rounded-start" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Catálogo General de Bandejas Portacables NUBAN</h5>
                                <p class="card-text">Características y componentes</p>
                                <DownloadLink url="https://api.nuban.ar/uploads/cat_nuban_pdf.pdf" fileName="cat_nuban_pdf.pdf" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card mb-3" style={{ height: "220px" }}>
                    <div class="row g-0">
                        <div class="col-md-2">
                            <img src="assets/img/catalogo_nuban_clip.jpg" class="img-fluid rounded-start" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Catálogo de Bandejas Portacables de Fondo Perforado NUBAN Clip</h5>
                                <p class="card-text">Características y componentes</p>
                                <DownloadLink url="https://api.nuban.ar/uploads/cat_nuban_clip_pdf.pdf" fileName="cat_nuban_clip_pdf.pdf" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card mb-3" style={{ height: "220px" }} >
                    <div class="row g-0">
                        <div class="col-md-2">
                            <img src="assets/img/catalogo_nuban_up.jpg" class="img-fluid rounded-start" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Catálogo de Bandejas Portacables tipo Escalera Linea Ultra Pesada NUBAN UP</h5>
                                <p class="card-text">Características y componentes</p>
                                <DownloadLink url="https://api.nuban.ar/uploads/cat_nuban_up_pdf.pdf" fileName="cat_nuban_up_pdf.pdf" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card mb-3" style={{ height: "220px" }} >
                    <div class="row g-0">
                        <div class="col-md-2">
                            <img src="assets/img/catalogo_nuban_clinch.jpg" class="img-fluid rounded-start" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Catálogo de Bandejas PortaCables Clinchadas tipo Escalera NUBAN Clinch</h5>
                                <p class="card-text">Características y componentes</p>
                                <DownloadLink url="https://api.nuban.ar/uploads/cat_nuban_clinch_pdf.pdf" fileName="cat_nuban_clinch_pdf.pdf" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card mb-3" style={{ height: "220px" }} >
                    <div class="row g-0">
                        <div class="col-md-2">
                            <img src="assets/img/catalogo_nuban_elementos_sujecion.jpg" class="img-fluid rounded-start" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Catálogo de Elementos de sujeciòn y soporte NUBAN</h5>
                                <p class="card-text">Características y componentes</p>
                                <DownloadLink url="https://api.nuban.ar/uploads/cat_sujecion_y_soporte_pdf.pdf" fileName="cat_sujecion_y_soporte_pdf.pdf" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card mb-3" style={{ height: "220px" }} >
                    <div class="row g-0">
                        <div class="col-md-2">
                            <img src="assets/img/catalogo-nuban-especiales.jpg" class="img-fluid rounded-start" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Catálogo de PRODUCTOS ESPECIALES NUBAN Bajo Pedido</h5>
                                <p class="card-text">Características y componentes</p>
                                <DownloadLink url="https://api.nuban.ar/uploads/cat_nuban_especiales_pdf.pdf" fileName="cat_sin_nuban_up_pdf.pdf" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card mb-3" style={{ height: "220px" }} >
                    <div class="row g-0">
                        <div class="col-md-2">
                            <img src="assets/img/catalogo_nuban_bpc.jpg" class="img-fluid rounded-start" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Catálogo de Bandejas Portacables tipo Escalera NUBAN Bpc</h5>
                                <p class="card-text">Características y componentes</p>
                                <DownloadLink url="https://api.nuban.ar/uploads/cat_sin_nuban_up_pdf.pdf" fileName="cat_sin_nuban_up_pdf.pdf" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default FrontEndListasDeCatalogos;

