import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import Header from "./FrontEnd_Header";
import AdminFooter from "./FrontEnd_Footer";
import AdminSidebar from "./Admin_Sidebar";

export default function AdminHome(props) {
  const [data, setData] = useState([]);
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  useEffect(() => {

    const user = AuthService.getCurrentUser();
    if (user) {
      setShowClienteBoard(user.roles.includes("ROLE_USER"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMINISTRADORES") || user.roles.includes("ROLE_MASTER"));

      if (!user.roles.includes("ROLE_ADMINISTRADORES") && !user.roles.includes("ROLE_MASTER")) {
        props.history.push("/home");
      }
      

    } else {
      props.history.push("/login");
    }

  }, []);

  return (
    <>
      <Header />
      <AdminSidebar />
      <main id="main" class="main">

        <div class="pagetitle">
          <h1>Administración</h1>
        </div>

        <section class="section contact">

          <div class="row gy-4">

            <div class="col-xl-12">

              <div class="row">

                <div class="col-lg-12">
                  <div class="info-box card">
                    <i class="ri-file-copy-line"></i>
                    <h3>Zona de Administración</h3>
                    <p></p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>

      <AdminFooter />
    </>

  );
}

//export default Home;
