import React, { useEffect, useState } from "react";
import UserService from "../services/user.service";

import * as yup from 'yup';
import { useFormik } from 'formik';

const checkEmail = async (values) => {
    const response = await UserService.validEmails(values,'ecommerce');
    return response.data;
}
const checkEmpresa = async (values) => {
    const response = await UserService.validEmpresa(values,'ecommerce');
    return response.data;
}

var mailant = "";
var resmailant = false;
var empant = "";
var resempant = false;

//Validacion del formulario
const validationSchema = yup.object({
    nombre: yup
        .string('Tu nombre es requerido')
        .required('Tu nombre requerido'),
    email: yup
        .string('Email requerido')
        .email('Email no valido')
        .required('Email requerido')
        .test('checkDuplUsername', 'El email ya esta en uso, utilize otro email', async function (value) {
            if (value) {
                if (value !== mailant) {
                    mailant = value;
                    const res = await checkEmail(value);
                    if (res) {
                        resmailant = false;
                        return false;
                    } else {
                        resmailant = true;
                        return true;
                    }
                } else {
                    return resmailant;
                }
            }
        }),
    empresa: yup
        .string('Empresa requerido')
        .required('Empresa requerido')
        .test('checkDuplUsername', 'La empresa ya esta en uso, utilize otro nombre', async function (value) {
            if (value) {
                if (value !== empant) {
                    empant = value;
                    const res = await checkEmpresa(value);
                    if (res) {
                        resempant = false;
                        return false;
                    } else {
                        resempant = true;
                        return true;
                    }
                } else {
                    return resempant;
                }
            }
        }),
    password: yup.string()
        .required('Password es requerido')
        .min(6, 'Password debe tener al menos 6 caracteres')
        .max(40, 'Password no debe exeder los 40 caracteres'),
    confirmPassword: yup.string()
        .required('Confirm Password es requerido')
        .oneOf([yup.ref('password'), null], 'Confirm Password no coinciden'),
    acceptTerms: yup.bool().oneOf([true], 'Debes aceptar los terminos y condiciones'),
});

export default function FrontEndRegister_ecommerce(props) {

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            nombre: '',
            email: '',
            password: '',
            empresa: '',
            producto: 'ecommerce',
            acceptTerms: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
            //alert(JSON.stringify(values, null, 2));
        },
    });

    const [shown, setShown] = useState(false);
    const [shown1, setShown1] = useState(false);

    const switchShown = () => setShown(!shown);
    const switchShown1 = () => setShown1(!shown1);

    const peticionPost = async (values) => {
        await UserService.addmodAdministradores(values.nombre, values.email, values.password, values.empresa, values.producto);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push("/administradores");
    }

    useEffect(() => {
        //setLoading(false);
    }, []);

    return (
        <>
            <div id="breadcrumb">
                <div class="container">
                    <div class="breadcrumb">
                        <li><a href="/">Inicio</a></li>
                        <li>Contacto</li>
                    </div>
                </div>
            </div>

            <section id="contact-page">
                <div class="container">
                    <div class="center">
                        <h2>Registrate en ecommerce</h2>
                        <p>Registrate y da de alta tu empresa o emprendimiento.</p>
                    </div>

                    <div class="row contact-wrap">

                        <form className="contact-form" onSubmit={formik.handleSubmit}>
                            <div class="col-sm-5 col-sm-offset-1">

                                <div class="form-group">
                                    <label>Nombre *</label>
                                    <input type="text" name="nombre" class="form-control"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                        helperText={formik.touched.nombre && formik.errors.nombre}
                                    />
                                    {formik.errors.nombre &&
                                        <div>
                                            {formik.errors.nombre}
                                        </div>}
                                </div>

                                <div class="form-group">
                                    <label>Email *</label>
                                    <input type="email" name="email" class="form-control"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                    {formik.errors.email &&
                                        <div>
                                            {formik.errors.email}
                                        </div>}
                                </div>

                                <div class="form-group">
                                    <label>Password</label>

                                    <div class="input-group">
                                        <input type={shown ? 'text' : 'password'} name="password" class="form-control"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                            helperText={formik.touched.password && formik.errors.password}
                                        />

                                        <span class="input-group-btn">
                                            <button class="btn btn-default" type="button" onClick={switchShown}>
                                                {shown ? 'Ocultar' : 'Ver'}
                                            </button>
                                        </span>

                                    </div>

                                    {formik.errors.password &&
                                        <div>
                                            {formik.errors.password}
                                        </div>}

                                </div>

                                <div class="form-group">
                                    <label>Confirmar Password</label>

                                    <div class="input-group">
                                        <input type={shown1 ? 'text' : 'password'} name="confirmPassword" class="form-control"
                                            value={formik.values.confirmPassword}
                                            onChange={formik.handleChange}
                                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                        />

                                        <span class="input-group-btn">
                                            <button class="btn btn-default" type="button" onClick={switchShown1}>
                                                {shown1 ? 'Ocultar' : 'Ver'}
                                            </button>
                                        </span>

                                    </div>

                                    {formik.errors.confirmPassword &&
                                        <div>
                                            {formik.errors.confirmPassword}
                                        </div>}

                                </div>

                                <div class="form-group">
                                    <label>Nombre de la Empresa *</label>
                                    <input type="text" name="empresa" class="form-control"
                                        value={formik.values.empresa}
                                        onChange={formik.handleChange}
                                        error={formik.touched.empresa && Boolean(formik.errors.empresa)}
                                        helperText={formik.touched.empresa && formik.errors.empresa}
                                    />
                                    {formik.errors.empresa &&
                                        <div>
                                            {formik.errors.empresa}
                                        </div>}
                                </div>

                                <div class="form-group">
                                    <input type="hidden" name="producto" class="form-control"
                                        value={formik.values.producto}
                                        onChange={formik.handleChange}
                                        error={formik.touched.producto && Boolean(formik.errors.producto)}
                                        helperText={formik.touched.producto && formik.errors.producto}
                                    />
                                </div>

                                <div className="form-group form-check">
                                    <input
                                        name="acceptTerms"
                                        type="checkbox"
                                        className="form-check-input"
                                        value={formik.values.acceptTerms}
                                        onChange={formik.handleChange}
                                        error={formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms)}
                                        helperText={formik.touched.acceptTerms && formik.errors.acceptTerms}
                                    />

                                    <label htmlFor="acceptTerms" className="form-check-label">
                                        He leido y acepto los terminos y condiciones
                                    </label>

                                    {formik.errors.acceptTerms &&
                                        <div>
                                            {formik.errors.acceptTerms}
                                        </div>}
                                </div>

                                <div class="form-group">
                                    <button type="submit" name="submit" class="btn btn-primary btn-lg">Registrarse</button>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </section >
        </>
    );
}

//export default Home;
