import React from "react";
import { useHistory } from "react-router-dom";

import FrontEndHeader from "./FrontEnd_Header";
import FrontEndFooter from "./FrontEnd_Footer";
import FrontEndSidebar from "./FrontEnd_Sidebar";

const FrontEndInstructivos = () => {
    const history = useHistory();

    const inicio = () => {
        history.push(process.env.PUBLIC_URL + "/inicio");
    }

    return (
        <>
            <FrontEndHeader />
            <FrontEndSidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>INSTRUCTIVOS</h1>
                </div>

                <section className="section contact">
                    <div className="row gy-4">
                        <div className="col-xl-12">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="info-box card">
                                        <video controls width="100%">
                                            <source src="assets/vid/instructivo1.mp4" type="video/mp4" />
                                            Tu navegador no admite la etiqueta de video.
                                        </video>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="info-box card">
                                        <video controls width="100%">
                                        <source src="assets/vid/instructivo2.mp4" type="video/mp4" />
                                            Tu navegador no admite la etiqueta de video.
                                        </video>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="info-box card">
                                        <video controls width="100%">
                                        <source src="assets/vid/instructivo3.mp4" type="video/mp4" />
                                            Tu navegador no admite la etiqueta de video.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <FrontEndFooter />
        </>
    );
}

export default FrontEndInstructivos;
