import React, { useState, useEffect } from "react";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import { useParams } from 'react-router';
import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import AdminSidebar from "./Admin_Sidebar";
import Grid from '@mui/material/Grid';
import FilesUpload from "./Admin_FilesUpload";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function AdminNoticias(props) {
  const [age, setAge] = React.useState('');
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  useEffect(() => {

    const user = AuthService.getCurrentUser();
    if (user) {
      setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMINISTRADORES") || user.roles.includes("ROLE_MASTER"));
    } else {
      props.history.push("/login");
    }

  }, []);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Header />
      <AdminSidebar />
      <main id="main" class="main">

        <div class="pagetitle">
          <h1>ARCHIVOS</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Inicio</a></li>
            </ol>
          </nav>
        </div>

        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Tipo de Archivo</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Tipo de Archivo"
                        onChange={handleChange}
                      >
                        <MenuItem value='noticia1_celular'>Noticia1 Celular</MenuItem>
                        <MenuItem value='noticia1_pc'>Noticia1 Pc</MenuItem>
                        <MenuItem value='noticia2_celular'>Noticia2 Celular</MenuItem>
                        <MenuItem value='noticia2_pc'>Noticia2 Pc</MenuItem>
                        <MenuItem value='noticia3_celular'>Noticia3 Celular</MenuItem>
                        <MenuItem value='noticia3_pc'>Noticia3 Pc</MenuItem>

                        <MenuItem value='lis_bpc'> Lista de precios NUBAN (Con y sin placas incluidas) Formato Excel.</MenuItem>
                        <MenuItem value='lis_nuban_up'>Lista de precios NUBAN (SOLO TRAMOS) Formato PDF. </MenuItem>
                        <MenuItem value='lis_sin_nuban_up_pdf'>Lista de precios NUBAN Formato PDF.</MenuItem>

                        <MenuItem value='cat_nuban_pdf'>Catálogo General de Bandejas Portacables NUBAN</MenuItem>
                        <MenuItem value='cat_sin_nuban_up_pdf'>Catálogo de Bandejas Portacables tipo Escalera NUBAN Bpc</MenuItem>
                        <MenuItem value='cat_nuban_clip_pdf'>Catálogo de Bandejas Portacables de Fondo Perforado NUBAN Clip</MenuItem>
                        <MenuItem value='cat_nuban_up_pdf'>Catálogo de Bandejas Portacables tipo Escalera Linea Ultra Pesada NUBAN UP</MenuItem>
                        <MenuItem value='cat_nuban_clinch_pdf'>Catálogo de Bandejas PortaCables Clinchadas tipo Escalera NUBAN Clinch</MenuItem>
                        <MenuItem value='cat_sujecion_y_soporte_pdf'>Catálogo de Elementos de sujeción y soporte NUBAN</MenuItem>
                        <MenuItem value='cat_nuban_especiales_pdf'>Catálogo de PRODUCTOS ESPECIALES NUBAN Bajo Pedido</MenuItem>
                        
                      </Select>
                    </FormControl>
                  </Box>

                </div>
              </div>

              <Grid item xs={12}>
                <FilesUpload id={id} documento={age} />
              </Grid>

            </div>

          </div>
        </section>

      </main>
      <Footer />
    </>

  );
}

//export default Home;
