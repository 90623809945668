import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { useSelector, useDispatch } from "react-redux";

import { init_to_dir, init_to_min, add_quantity, init_to_prod, init_to_prod_quantity, clear_cart, add_to_cart_quantity, add_to_cart_recuperar, init_to_cart } from "../reducers/shoppingSlice";

import { useHistory } from "react-router-dom";
import UserService from "../services/user.service";
import IconButton from "@mui/material/IconButton";
import RestoreIcon from "@mui/icons-material/Restore"; // ícono de restaurar/recuperar

const ProductItemRecuperar = ({ data, addToCart }, props) => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  let { id, number, fecha, total } = data;


  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { moneda } = state;
  const [show, setShow] = useState(true);
  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/img/sinimagen.png";
  }

  const handleChange = e => {
    const { name, value, id } = e.target;
    dispatch(add_to_cart_quantity({ id: id, quantity: value }))
  }

  const hazAlgo = async () => {
    dispatch(clear_cart());

    try {
      const result = await UserService.getArticulosList();
      if (result.data) {
        dispatch(init_to_prod(result.data));
        dispatch(init_to_prod_quantity());
        //setData(result.data);
      } else {
        console.log('no hay datos')
      }
    } catch (e) {
      console.log(e);
    }

  }

  const hazAlgoMas = async (id) => {
    try {

      const result = await UserService.getPedidoRecuperar(id);
      if (result.data) {

        result.data.map(dir => {
          dispatch(init_to_dir(dir.id));
          dispatch(add_to_cart_recuperar(dir.product_sku));
          dispatch(add_quantity({ id: id, quantity: dir.quantity }));
        })

        const GetDirecciones = async () => {
          try {

            const result = await UserService.getDirecciones();
            if (result.data) {

              result.data.map(dir => {
                //dispatch(init_to_dir(dir.id));
                if (dir.predeterminada == 'true')
                  dispatch(init_to_dir(dir.id));
              })

            } else {
              console.log('no hay datos')
            }
          } catch (e) {
            console.log(e);
          }
        }
        GetDirecciones();

        const GetMinimo = async () => {
          try {

            const result = await UserService.getMinimo();
            if (result.data) {
              dispatch(init_to_min(result.data));
            } else {
              console.log('no hay datos')
            }
          } catch (e) {
            console.log(e);
          }
        }
        GetMinimo();


      } else {
        console.log('no hay datos')
      }
    } catch (e) {
      console.log(e);
    }
  }

  const falloCallback = async (values) => {
  }

  const ocultar = async (values) => {
    history.push(process.env.PUBLIC_URL + "/recuperado");
  }

  const abrirCerrarModalEditar = (id) => {

    const GetArticulos0 = async () => {
      try {
        await hazAlgo();
        await hazAlgoMas(id);
        props.history.push("/");

      } catch (error) {
        falloCallback(error);
      }
    }
    GetArticulos0();
    ocultar();

  }

  return (

    <>

      {show ? (
        <tr>
          <td scope="row">
            {number}
          </td>
          <td>{fecha}</td>
          <td>{moneda} {parseFloat(total).toFixed(2)}</td>
          <td>
            <IconButton
              aria-label="recuperar"
              onClick={() => abrirCerrarModalEditar(id)}
              color="primary"
              sx={{ cursor: "pointer" }} // Aplicamos cursor "pointer"
            >
              <RestoreIcon />
            </IconButton>
          </td>
        </tr>
      ) : (
        <tr>
          <td scope="row">
            El pedido fue recuperado y cargado automaticamente al carrito de compras
          </td>
        </tr>
      )}

    </>

  );
};

export default ProductItemRecuperar;