import React from 'react';
import { useMediaQuery } from 'react-responsive';

function ResponsiveImage({ src, alt }) {
  const isMobile = useMediaQuery({ maxWidth: 640 });

  let imageSrc = src;

  if (isMobile) {
    imageSrc = imageSrc.replace('.jpg', '_celular.jpg');
  } else {
    imageSrc = imageSrc.replace('.jpg', '_pc.jpg');
  }

  const updatedSrc = `${imageSrc}?${new Date().getTime()}`;

  return (
    <div className="image-container">
      <img
        src={updatedSrc}
        alt={alt}
        style={{ width: '100%', height: 'auto' }}
        rel="preload"
      />
    </div>
  );
}

export default ResponsiveImage;
