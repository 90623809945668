import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import { makeStyles } from '@mui/styles';

import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import Sidebar from "./FrontEnd_Sidebar";
import ListasDePrecios from "./FrontEnd_ListasDePrecios";


import Paper from '@mui/material/Paper';
import { Modal, Button } from '@mui/material';

import Breadcrumbs from '@mui/material/Breadcrumbs';

import Link from '@mui/material/Link';

import {
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";

import MaterialTable from 'material-table';

let direction = "ltr";

const theme = createTheme(
    adaptV4Theme({
        direction: direction,
        palette: {
            mode: "light",
        },
    })
);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        position: 'absolute',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));

export default function FrontEndListas(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);

    useEffect(() => {

        const user = AuthService.getCurrentUser();
        if (user) {
            setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
            setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
        } else {
            props.history.push("/login");
        }

    }, []);

    const inicio = async () => {
        props.history.push("/");
    }

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>LISTA DE PRECIOS</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Inicio</a></li>
                        </ol>
                    </nav>
                </div>

                <section class="section">
                    <div class="row">
                        <div class="col-lg-12">
                            Podrá descargar nuestra Lista de Precios en cualquiera de los siguientes formatos:
                            <ListasDePrecios />
                        </div>
                    </div>
                </section> 
            </main>
            <Footer />
        </>

    );
}

//export default Home;
