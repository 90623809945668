import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FrontEndHeader from "./FrontEnd_Header";
import FrontEndFooter from "./FrontEnd_Footer";
import FrontEndSidebar from "./FrontEnd_Sidebar";

const FrontEndOffline = () => {
    const history = useHistory();

    const [currentUser, setCurrentUser] = useState({
        username: '',
    });

    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);

    useEffect(() => {

    }, []);

    const inicio = () => {
        history.push(process.env.PUBLIC_URL + "/inicio");
    }

    return (
        <>
            <FrontEndHeader />
            <FrontEndSidebar />
            <main id="main" class="main">
                <div class="pagetitle">
                    <h1>EN MANTENIMIENTO</h1>
                </div>
                <section class="section contact">
                    <div class="row gy-4">
                        <div class="col-xl-12">
                            <div class="row">
                                <div class="col-lg-12">
                                    <img src="/assets/img/offline.jpg" alt="Stop" style={{ width: "300px", height: "300px" }} />
                                    <h2>Estamos actualizando nuestro sitio web</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <FrontEndFooter />
        </>
    );
}

export default FrontEndOffline;
