import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import FrontEndHeader from "./FrontEnd_Header";
import FrontEndFooter from "./FrontEnd_Footer";
import FrontEndSidebar from "./FrontEnd_Sidebar";

import { useDispatch } from "react-redux";

export default function FrondEndDatosUtiles(props) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  useEffect(() => {

    const user = AuthService.getCurrentUser();

    if (user) {
      setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    } else {
      props.history.push("/login");
    }

  }, [dispatch]);

  return (
    <>
      <FrontEndHeader />
      <FrontEndSidebar />
      <main id="main" class="main">

        <div class="pagetitle">
          <h1>DATOS ÚTILES</h1>
        </div>

        <section class="section contact">

          <div class="row gy-4">

            <div class="col-xl-12">

              <div class="row">
 
                <div class="col-lg-6">
                  <div class="info-box card1">
                    <i class="bi bi-geo-alt"></i>
                    <h3>Planta Pilar</h3>
                    <p><a href="https://goo.gl/maps/kg1QJNYLwYf3JFq3A" target="_blank">Calle 15 y Calle 9 - Parque Industrial Pilar.</a> <br />(B1629) Fatima Provincia de Buenos Aires.</p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="info-box card1">
                    <i class="ri-phone-line"></i>
                    <h3>Teléfono</h3>
                    <p><a href="tel:11-4716-3700" target="_blank">11-4716-3700</a></p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="info-box card1">
                    <i class="ri-mail-send-line"></i>
                    <h3>E-mail</h3>
                    <p><a href="mailto:nuban@industriabasica.com.ar" target="_blank">nuban@industriabasica.com.ar</a></p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="info-box card1">
                    <i class="ri-whatsapp-line"></i>
                    <h3>WhatsApp</h3>
                    <p><a href="https://api.whatsapp.com/send/?phone=5491151318108" target="_blank">+54 9 11 5131-8108</a></p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="info-box card1">
                    <i class="ri-file-copy-line"></i>
                    <a href="http://microsite.industriabasica.net/legajo.pdf" target="_blank">
                      <h3>Descarga desde aquí<br /> nuestros Formularios impositvos</h3>
                    </a>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="info-box card1">
                    <i class="ri-file-copy-line"></i>
                    <a href="/bancos">
                      <h3>Datos Bancarios</h3>
                    </a>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="info-box card1">
                    <i class="ri-file-copy-line"></i>
                    <h3>Actualización Impositiva</h3>
                    <a href="http://microsite.industriabasica.net/" target="_blank">
                      <p>Ingresá aquí para actualizar sus datos impositivos</p>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>

      <FrontEndFooter />
    </>

  );
}

