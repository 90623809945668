import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import Sidebar from "./FrontEnd_Sidebar";
import { useHistory } from "react-router-dom";
import { remove_one_from_cart } from "../reducers/shoppingSlice";
import Total from "./FrontEnd_Total.component";
import CartItemHistorial from "./FrontEnd_CartItemHistorial.component";
import FooterText from "./FrontEnd_FooterText";
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import AuthService from "../services/auth.service";
import { useParams } from 'react-router';

import UserService from "../services/user.service";
import SnackbarMessage from './SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

//Validacion del formulario
const validationSchema = yup.object({
  orden_compra: yup
    .string('Orden de compra'),
  customer_note: yup
    .string('Nota'),
});

const FrontEnd_Cart_Historial = () => {
  const [isLoading, setIsLoading] = useState(true);

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      orden_compra: '',
      customer_note: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(cart, values);
    },
  });

  const history = useHistory();

  const { id } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  //const { cart, moneda, cantidad } = state;
  const [cart, setCart] = useState([]);

  const [vacio, setVacio] = useState(true);

  const delFromCart = (id, all = false) => {
    if (all) {
      dispatch(remove_one_from_cart(id));
    } else {
      dispatch(remove_one_from_cart(id));
    }
  }

  const peticionPost = async (values) => {
    history.push(process.env.PUBLIC_URL + "/historial");
  }

  const cerrarEditar = () => {
    history.push(process.env.PUBLIC_URL + "/gracias");
  }

  useEffect(() => {

    const GetHistorial = async () => {
      try {
        setIsLoading(true);
        const result = await UserService.getPedidoHistorial(id);
        if (result) {
          setCart(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        console.log(e)
        handleShowMessage('<div><h4>Error al cargar los datos.<br/>Es posible que haya un problema con la conexión a Internet o con el servidor.<br/> Para recargar la página, puedes presionar F5 en tu computadora o deslizar la pantalla hacia abajo en tu celular.<br/> Si el problema persiste, por favor, inténtalo de nuevo más tarde o contacta al soporte técnico.</h4></div>');
      }
    }
    GetHistorial();

  }, []);

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" class="main">

        <div class="pagetitle">
          <h1>MI COMPRA</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Inicio</a></li>
            </ol>
          </nav>
        </div>

        {vacio && (
          <section class="section">
            <div class="row">
              <div class="col-lg-12">
                <div class="card top-selling overflow-auto">

                  <div class="card-body pb-0">
                    <h5 class="card-title">Carrito <span></span></h5>

                    <form onSubmit={formik.handleSubmit}>
                      <table class="table table-borderless" border={1}>

                        <thead>
                          <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Descripción</th>
                            <th scope="col">Precio</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">SubTotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cart.map((item, index) => (
                            <CartItemHistorial key={index} data={item} delFromCart={delFromCart} />
                          ))}
                          <Total data={cart} />
                          <tr>
                            <td>
                            </td>
                          </tr>
                          <tr>
                          </tr>
                          <tr>
                            <td colSpan={4}>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={4}></td>
                            <td colSpan={2}>

                              <table>
                                <tr>
                                  <td>
                                  </td>
                                  <td>
                                    <button type="submit" class="btn btn-primary">Cerrar</button>
                                  </td>
                                </tr>
                              </table>

                            </td>
                          </tr>
                        </tbody>

                        <FooterText />

                      </table>
                    </form>

                  </div>

                </div>
              </div>
            </div>
          </section>
        )}
        {!vacio && (
          <>Carrito Vacio</>
        )}
      </main>

      <SnackbarMessage
        open={open}
        message={errorMessage}
        severity="success"
        onClose={handleCloseMessage}
      />

      <Footer />

    </>
  );
};

export default FrontEnd_Cart_Historial;