import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { add_to_cart_quantity, add_quantity } from "../reducers/shoppingSlice";
import { Modal, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: 5,
    padding: (2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconos: {
    cursor: 'pointer'
  },
  inputMaterial: {
    width: '100%'
  }
}));

const ProductItem = ({ data, addToCart }) => {
  const styles = useStyles();

  let { id, product_sku, name, price, quantity } = data;

  //let quantity = data.quantity.quantity;

  let quantity1 = 0;
  let quantity2 = quantity;

  if (data.quantity !== undefined) {
    quantity1 = data.quantity;
    if (quantity1.quantity !== undefined)
      quantity2 = quantity1.quantity;
  }

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [modalEditar, setModalEditar] = useState(false);

  const { moneda } = state;

  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/img/sinimagen.png";
  }

  const handleChange = e => {
    const { name, value, id } = e.target;
    dispatch(add_to_cart_quantity({ id: id, quantity: value }));
    dispatch(add_quantity({ id: id, quantity: value }));
  }

  const abrirCerrarModalEditar = (id) => {
    //getDireccion(id);
    setModalEditar(!modalEditar);
  } 

  const bodyEditar = (
    <>
      <div className={styles.modal}>
        <img onError={addDefaultSrc} src={`/assets/img/` + `${product_sku}` + ".jpg"} height="350px" alt={`${product_sku}`} />

      <Grid item xs={12}>
        <div class="col-lg-12" style={{ textAlign: "right" }} >
          <button onClick={() => abrirCerrarModalEditar()} class="btn btn-primary">Cerrar</button>
        </div>
      </Grid>
      </div>

    </>
  )

  return (
    <>
      <tr>
        <td scope="row">
          <img onClick={() => abrirCerrarModalEditar()} onError={addDefaultSrc} src={`/assets/img/` + `${product_sku}` + ".jpg"} alt={`${product_sku}`} />
        </td>
        <td>{product_sku}</td>
        <td>
          <p onClick={() => abrirCerrarModalEditar()} class="text-primary fw-bold"> {name}</p>
        </td>
        <td>{moneda} {price}</td>
        <td>
          <input type="number" id={id} name="quantity" min="0" value={quantity2} onChange={handleChange} />
        </td>
      </tr>

      <Modal
        open={modalEditar}
        onClose={abrirCerrarModalEditar}>
        {bodyEditar}
      </Modal>

    </>
  );
};

export default ProductItem;