import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete'; // Importa el icono de eliminación de Material-UI

import { add_to_cart_quantity, add_quantity } from "../reducers/shoppingSlice";
import { Modal, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.5)',
    padding: '2rem 4rem 3rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconos: {
    cursor: 'pointer'
  },
  inputMaterial: {
    width: '100%'
  }
}));

// Estilos en línea para el botón con el icono de eliminación
const buttonStyle = {
  margin: 0, // Ajusta el margen del botón a cero
};

const ProductItem = ({ data, delFromCart }) => {
  const styles = useStyles();

  let { id, product_sku, name, price, quantity } = data;

  let quantity2 = quantity || 0;

  const dispatch = useDispatch();
  const [modalEditar, setModalEditar] = useState(false);

  const { moneda } = useSelector((state) => state);

  const handleChangec = e => {
    const { value } = e.target;
    dispatch(add_to_cart_quantity({ id: id, quantity: value }));
    dispatch(add_quantity({ id: id, quantity: value }));
  }

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  }

  const bodyEditar = (
    <div className={styles.modal}>
      <img src={`/assets/img/${product_sku}.jpg`} height="350px" alt={product_sku} />

      <Grid item xs={12}>
        <div className="col-lg-12" style={{ textAlign: "right" }} >
          <button onClick={abrirCerrarModalEditar} className="btn btn-primary">Cerrar</button>
        </div>
      </Grid>
    </div>
  );

  return (
    <>
      <tr>
        <td scope="row">
          <img onClick={abrirCerrarModalEditar} src={`/assets/img/${product_sku}.jpg`} alt={product_sku} />
        </td>
        <td>{product_sku}</td>
        <td>
          <p onClick={abrirCerrarModalEditar} className="text-primary fw-bold">{name}</p>
        </td>
        <td>{moneda} {price}</td>
        <td>
          <input type="number" id={id} name="quantity" min="0" value={quantity2} onChange={handleChangec} />
        </td>
        <td scope="row">
          {/* Agrega los estilos en línea al botón */}
          <button onClick={(e) => { e.preventDefault(); delFromCart(id); }} className="btn btn-primary" style={buttonStyle} title="Eliminar" >
            <DeleteIcon /> {/* Icono de eliminación de Material-UI */}
          </button>
        </td>
      </tr>

      <Modal
        open={modalEditar}
        onClose={abrirCerrarModalEditar}>
        {bodyEditar}
      </Modal>
    </>
  );
};

export default ProductItem;
