import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { useParams } from 'react-router';
import SnackbarMessage from './SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

const FrontEndMenuCategoriasPrincipales = (props) => {

    const { id } = useParams();
    console.log(id);
    const [data, setData] = useState([]);

    useEffect(() => {

        const GetData = async () => {
            try {
                //Busca en category_xref todas las que tengan category_parent_id == 0
                const result = await UserService.getCategoriasNombre(id);
                if (result.data) {

                    let dato = result.data.map(consola => {
                        return (
                            <div class="card mb-3">
                                <div class="row g-0">
                                    <div class="col-md-8">
                                        <div class="card-body">

                                            {consola.hija.length === 0 ? (
                                                <div><a href={`${process.env.PUBLIC_URL}/articulos/` + `${consola.cat_child_id}`}> {consola.name}</a></div>
                                            ) : (
                                                <h5 class="card-title">{consola.name}</h5>
                                            )}

                                            {consola.hija.map((dataItem) => {
                                                return (
                                                    <div><a href={`${process.env.PUBLIC_URL}/articulos/` + `${dataItem.id}`}> {dataItem.name}</a></div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        );
                    });

                    setData(dato);
                } else {
                    handleShowMessage('500');
                }
            } catch (e) {
                console.log(e);
                handleShowMessage('500');
            }
        }
        GetData();


    }, []);

    //Para el uso del componente de mensajes 
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <>
            {data}
            <SnackbarMessage
                open={open}
                message={errorMessage}
                severity="success"
                onClose={handleCloseMessage}
            />
        </>
    );
}
export default FrontEndMenuCategoriasPrincipales;

