import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
 
import { makeStyles } from '@mui/styles';

import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import Sidebar from "./FrontEnd_Sidebar";
import Historial from "./FrontEnd_Historial";
import { useParams } from 'react-router';

import { 
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";

let direction = "ltr";

const theme = createTheme(
    adaptV4Theme({
        direction: direction,
        palette: {
            mode: "light",
        },
    })
);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        position: 'absolute',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));

export default function FrontEndHistorialPedidos(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const { id } = useParams();

    useEffect(() => {
 
        const user = AuthService.getCurrentUser();
        if (user) {
          setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
          setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
        } else {
          props.history.push("/login");
        }

    }, []);

    const inicio = async () => {
        props.history.push("/");
    }

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>HISTORIAL DE PEDIDOS</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Inicio</a></li>
                            <li class="breadcrumb-item">Categoria</li>
                        </ol>
                    </nav>
                </div>

                <section class="section dashboard">
                    <div class="row">
                        <div class="col-12">
                            <div class="card top-selling overflow-auto">

                                <div class="filter">
                                    <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <li class="dropdown-header text-start">
                                            <h6>Filtro</h6>
                                        </li>

                                        <li><a class="dropdown-item" href="#">Today</a></li>
                                        <li><a class="dropdown-item" href="#">This Month</a></li>
                                        <li><a class="dropdown-item" href="#">This Year</a></li>
                                    </ul>
                                </div>

                                <div class="card-body pb-0">
                                    <h5 class="card-title">Articulos<span></span></h5>

                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nro de pedido</th>
                                                <th scope="col">Fecha</th>
                                                <th scope="col">Total</th>
                                                <th scope="col">Ampliar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <Historial />
                                        </tbody>
                                    </table>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </main>
            <Footer />
        </>

    );
}

//export default Home;
