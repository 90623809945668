import React, { useState } from "react";

const AdminSidebar = ({ tipo }) => {
 

    return (
        <>
            <aside id="sidebar" class="sidebar" style={{ color: "#fff", backgroundColor: "rgb(0, 79, 158)" }}>

                <ul class="sidebar-nav" id="sidebar-nav">

                    <li class="nav-item">
                        <a class="nav-link " href="/">
                            <i class="bi bi-grid"></i>
                            <span>Volver al Ecommerce</span>
                        </a>   
                    </li>

                    <li class="nav-item">
                        <a class="nav-link collapsed" data-bs-target="#components-nav" href="/novedades">
                            <i class="ri-shopping-cart-2-line"></i><span>Novedades</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link collapsed" data-bs-target="#components-nav" href="/adminnoticias">
                            <i class="ri-shopping-cart-2-line"></i><span>Subir Archivos</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link collapsed" data-bs-target="#components-nav" href="/adminclientes">
                            <i class="bi bi-journal-text"></i><span>Actualizar Clientes</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link collapsed" data-bs-target="#components-nav" href="/admindescuentos">
                            <i class="bi bi-journal-text"></i><span>Desc. Cli y minimo</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link collapsed" data-bs-target="#tables-nav" href="/adminarticulos">
                            <i class="bi bi-layout-text-window-reverse"></i><span>Actualizar Articulos</span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link collapsed" data-bs-target="#charts-nav" href="/admintextos">
                            <i class="bi bi-bar-chart"></i><span>Textos</span>
                        </a>
                   </li>

                   <li class="nav-item">
                        <a class="nav-link collapsed" data-bs-target="#charts-nav" href="/administradores">
                            <i class="bi bi-bar-chart"></i><span>Administradores</span>
                        </a>
                   </li>

                   <li class="nav-heading">--</li>

                   <li class="nav-item">
                        <a class="nav-link collapsed" data-bs-target="#charts-nav" href="/adminofflineonline">
                            <i class="bi bi-bar-chart"></i><span>Offline / Onlne </span>
                        </a>
                   </li>

                    <li class="nav-heading">--</li>

                </ul>

            </aside>

        </>

    );
}
export default AdminSidebar;

