import axios from "axios";
import jwt_decode from "jwt-decode";

const API_URL = "https://api.nuban.ar/api/";
//const API_URL = "https://api.casiba.ar/api/";

const login = async (_username, _password) => {
  try {
    const response = await axios.post(
      API_URL + "login_check",
      `_username=${encodeURIComponent(_username)}&_password=${encodeURIComponent(_password)}`,
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' } }
    );

    if (response.data.token) {
      console.log(response.data.token)
      const data = jwt_decode(response.data.token);
      data.accessToken = response.data.token;
      data.username = data.email;
      localStorage.setItem("user", JSON.stringify(data));
      return data;
    } else {
      throw new Error("Token not found in the response");
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw error; // Propagate the error to the caller
  }
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export default {
  login,
  logout,
  getCurrentUser,
};
