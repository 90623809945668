import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { add_to_cart, init_to_prod, init_to_prod_quantity } from "../reducers/shoppingSlice";
import { useSelector, useDispatch } from "react-redux";
import ProductItem from "./FrontEnd_ProductItem";
import SnackbarMessage from './SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

//const MenuSubCategorias = (props) => {
const FrontEndMenuArticulos = ({ tipo, addToCart }) => {

    //const [data, setData] = useState([]);

    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const { products, cart } = state;

    useEffect(() => {

        const GetDatos = async () => {
            try {

                //Busca articulos == 0
                const result = await UserService.getArticulos(tipo);
                if (result.data) {
                    dispatch(init_to_prod(result.data));
                    dispatch(init_to_prod_quantity());
                    //setData(result.data);
                } else {
                    console.log('no hay datos')
                    //props.history.push("/login");
                    handleShowMessage('500');
                }
            } catch (e) {
                console.log(e);
                handleShowMessage('500');
                //props.history.push("/login");
            }
        }
        GetDatos();

    }, [dispatch]);

    //Para el uso del componente de mensajes 
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <>
            {
                products.map((product) => (
                    <ProductItem
                        key={product.id}
                        data={product}
                        addToCart={() => dispatch(add_to_cart(product.id))}
                    />
                ))
            }

            <div>
                <SnackbarMessage
                    open={open}
                    message={errorMessage}
                    severity="success"
                    onClose={handleCloseMessage}
                />

            </div>

        </>
    );
}

export default FrontEndMenuArticulos;

