import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import FrontEndHeader from "./FrontEnd_Header";
import FrontEndFooter from "./FrontEnd_Footer";
import FrontEndSidebar from "./FrontEnd_Sidebar";

const FrontEndGracias = () => {
  const history = useHistory();

  const [currentUser, setCurrentUser] = useState({
    username: '',
  });

  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  useEffect(() => {

  }, []);


  const inicio = () => {
    history.push(process.env.PUBLIC_URL + "/inicio");
  }

  return (

    <>
      <FrontEndHeader />
      <FrontEndSidebar />
      <main id="main" class="main">

        <div class="pagetitle">
          <h1>Datos Bancarios</h1>
        </div>

        <section class="section contact">

          <div class="row gy-4">

            <div class="col-xl-12">

              <div class="row">

                <div class="col-lg-12">
                  <div class="info-box card">
                    <i class="ri-file-copy-line"></i>
                    <h3>POR FAVOR TENER EN CUENTA QUE LA CTA CTE 11586/7 SANTANDER RIO FUE DADA DE BAJA,
                      LAS CUENTAS VIGENTES SON</h3>
                    <p>

                      <div class="col-lg-6">
                        <div class="info-box card1">
                          <h3>BANCO PROVINCIA DE BUENOS AIRES.</h3>
                          <p>
                            Sucursal Parque Industrial Pilar (Nº 7183)<br />
                            Cuenta Corriente en $ N° 50343/1<br />
                            Titular: Industria Básica S.A.<br />
                            Cuit N° 30-70114469-0<br />
                            CBU 0140179601718305034314
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="info-box card1">
                          <h3>BANCO SANTANDER RIO S.A.</h3>
                          <p>
                            Sucursal Parque Industrial Pilar (Nº 369)<br />
                            Cuenta Corriente en $ N° 476/7<br />
                            Titular: Industria Básica S.A.<br />
                            Cuit N° 30-70114469-0<br />
                            CBU 0720369320000000047672
                          </p>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="info-box card1">
                          <h3>BANCO FRANCES</h3>
                          <p>
                            Sucursal Pilar (Nº 133)<br />
                            Cuenta Corriente en $ N° 003915/7<br />
                            Titular: Industria Básica S.A.<br />
                            Cuit N° 30-70114469-0<br />
                            CBU 0170133920000000391571
                          </p>
                        </div>
                      </div>

                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>

      <FrontEndFooter />
    </>


  );
}

export default FrontEndGracias;