import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import { makeStyles } from '@mui/styles';

import { useOnlineStatus } from "./FrontEnd_useOnlineStatus";
import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import Sidebar from "./FrontEnd_Sidebar";
import MenuCategorias0 from "./FrontEnd_MenuCategorias0";

import Paper from '@mui/material/Paper';
import { Modal, Button } from '@mui/material';

import Breadcrumbs from '@mui/material/Breadcrumbs';

import Link from '@mui/material/Link';

import {
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";

import MaterialTable from 'material-table';

let direction = "ltr";

const theme = createTheme(
    adaptV4Theme({
        direction: direction,
        palette: {
            mode: "light",
        },
    })
);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        position: 'absolute',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));

export default function FrontEnd_Categorias(props) {
    const styles = useStyles();
    const classes = useStyles();
    const [data, setData] = useState(false);
    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);

    useEffect(() => {

        const GetData = async () => {
            try {
                const result = await UserService.getDeuda();
                if (result.data) {
                    //const res = JSON.parse(result);
        
                    if (result.data == "true") {
                        setData(true);
                        setModalEditar(true);
                    } else {
                        setModalEditar(false);
                        setData(false);
                    }
        
                } else {
                    //props.history.push("/login");
                    setData(false);
                }
            } catch (e) {
                console.log(e);
                // Do something else with the error, e.g. log it or show a message to the user.
                setData(false);
            }
        };
        GetData();
        
        
    }, []);

    const inicio = async () => {
        props.history.push("/");
    }

    const abrirCerrarModalEditar = (id) => {
        setModalEditar(false);
        //getDireccion(id);
    }

    const bodyEditar = (
        <>
            <div className={styles.modal}>
            <h1>Su pedido no será procesado hasta tanto no regularice el estado de su cuenta corriente, por favor comunicarse con Cobranzas</h1>
                <Grid item xs={12}>
                    <div class="col-lg-12" style={{ textAlign: "right" }} >
                        <button onClick={() => abrirCerrarModalEditar()} class="btn btn-primary">Cerrar</button>
                    </div>
                </Grid>
            </div>

        </>
    )

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>CREACIÓN DE PEDIDOS</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Inicio</a></li>
                        </ol>
                    </nav>
                </div>

                <section class="section">
                    <div class="row">
                        <MenuCategorias0 />
                    </div>
                </section>

            </main>
            <Footer />

            <Modal
                open={modalEditar}
                onClose={abrirCerrarModalEditar}>
                {bodyEditar}
            </Modal>

        </>

    );
}

//export default Home;
