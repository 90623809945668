import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NumericFormat } from 'react-number-format';
import DeleteIcon from '@mui/icons-material/Delete'; // Importa el icono de eliminación de Material-UI
import { add_to_cart_quantity, add_quantity } from "../reducers/shoppingSlice";

const FrontEndCartItem = ({ data, delFromCart }) => {
  let { id, product_sku, name, price, quantity } = data;

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { moneda } = state;

  const [quantity2, setQuantity2] = useState(quantity);

  // Estilos en línea para el botón con el icono de eliminación
  const buttonStyle = {
    margin: 0, // Ajusta el margen del botón a cero
  };

  const handleChange = e => {
    const { name, value, id } = e.target;
    const newQuantity = e.target.value

    setQuantity2(newQuantity);
    dispatch(add_to_cart_quantity({ id: id, quantity: value }));
    dispatch(add_quantity({ id: id, quantity: value }));
  }

  return (
    <tr>
      <td scope="row">
        {product_sku}
      </td>
      <td scope="row">
        {name}
      </td>
      <td scope="row">
        {moneda} 
        <NumericFormat
          value={price}
          decimalScale={2}
          fixedDecimalScale={true}
          displayType={'text'}
        />
      </td>
      <td>
        <input
          type="number"
          id={id}
          name="quantity"
          min="0"
          value={quantity2}
          onChange={handleChange}
        />

      </td>
      <td scope="row">
        {moneda}
        <NumericFormat
          value={price * quantity2}
          decimalScale={2}
          fixedDecimalScale={true}
          displayType={'text'}
        />
      </td>

      <td scope="row">
        {/* Agrega los estilos en línea al botón */}
        <button onClick={(e) => { e.preventDefault(); delFromCart(id); }} className="btn btn-primary" style={buttonStyle} title="Eliminar" >
          <DeleteIcon /> {/* Icono de eliminación de Material-UI */}
        </button>
      </td>

    </tr>
  );
};

export default FrontEndCartItem;
