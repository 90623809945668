import React, { useState } from "react";
import { Button, TextField } from '@mui/material';

import UserService from "../services/user.service";
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { init_to_prod, init_to_prod_quantity } from "../reducers/shoppingSlice";

import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
    search: yup
        .string('Ingrese una descripcion a buscar...')
        .required('Ingrese una descripcion a buscar...'),
});

const FrontEnd_Search = ({ tipo }) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            search: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });
 
    const peticionPost = async (values) => {
        const result = await UserService.getSearchArticulos(values.search, 'desc');
        if (result.data) {
            dispatch(init_to_prod(result.data));
            dispatch(init_to_prod_quantity());
        }
    }

    const cerrarEditar = () => {
        history.push(process.env.PUBLIC_URL + "/category");
    }

    return (
        <div class="search-bar">
            <form class="search-form d-flex align-items-center" onSubmit={formik.handleSubmit}>
            <TextField
                  name="search"
                  label="Descripción a buscar..."
                  autoFocus={true}
                  value={formik.values.search}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.search && Boolean(formik.errors.search)}
                  helperText={formik.touched.search && formik.errors.search}
                />
                <Button color="primary" title="Buscar" type="submit"><i class="bi bi-search"></i></Button>
            </form>
        </div>
    );
}

export default FrontEnd_Search;

