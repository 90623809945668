import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProductItem from "./FrontEnd_ProductItem";
import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import Sidebar from "./FrontEnd_Sidebar";
import Search_Codigo from "./FrontEnd_Search_Codigo";
import Search_Descripcion from "./FrontEnd_Search_Descripcion";
import { useHistory } from "react-router-dom";
import { add_to_cart } from "../reducers/shoppingSlice";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import { Modal, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
      width: '100%',
  },
  container: {
      maxHeight: 440,
  },
  modal: {
      position: 'absolute',
      width: 400,
      backgroundColor: '#fff',
      border: '2px solid #000',
      boxShadow: 5,
      padding: (2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
  },
  iconos: {
      cursor: 'pointer'
  },
  inputMaterial: {
      width: '100%'
  }
}));

const ShoppingCart = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { products, cart } = state;

  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);

  useEffect(() => {

    // NO TOMA EL ID NI POR GET NI POST EN EL REST5 VER...
    const GetData = async () => {
      try {
        //const result = await UserService.getArticulos();
        const result = await UserService.getDeuda();
        if (result.data) {
          //const res = JSON.parse(result);

          if (result.data == "true") {
            setData(true);
            setModalEditar(true);
          } else {
            setModalEditar(false);
            setData(false);
          }

        } else {
        }
      } catch (e) {
        console.log(e);
      }
    }
    GetData();

  }, []);

  const abrirCerrarModalEditar = (id) => {
    setModalEditar(false);
    //getDireccion(id);
}

const bodyEditar = (
    <>
        <div className={styles.modal}>
        <h1>Su pedido no será procesado hasta tanto no regularice el estado de su cuenta corriente, por favor comunicarse con Cobranzas</h1>
            <Grid item xs={12}>
                <div class="col-lg-12" style={{ textAlign: "right" }} >
                    <button onClick={() => abrirCerrarModalEditar()} class="btn btn-primary">Cerrar</button>
                </div>
            </Grid>
        </div>

    </>
)

  
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" class="main">

        <div class="pagetitle">
          <h1>BUSCADOR</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Inicio</a></li>
            </ol>
          </nav>
        </div>

        <nav>
          <ol class="breadcrumb">
            <Search_Codigo />
            <Search_Descripcion />
          </ol>
        </nav>

        <section class="section dashboard">
          <div class="row">
            <div class="col-12">
              <div class="card top-selling overflow-auto">

                <div class="card-body pb-0">
                  <h5 class="card-title">Artículos<span></span></h5>

                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Imagen</th>
                        <th scope="col">Código</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Precio</th>
                        <th scope="col">Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product) => (
                        <ProductItem
                          key={product.id}
                          data={product}
                          addToCart={() => dispatch(add_to_cart(product.id))}
                        />
                      ))}
                    </tbody>
                  </table>

                </div>

              </div>

            </div>
          </div>
        </section>

      </main>
      <Footer />

      <Modal
        open={modalEditar}
        onClose={abrirCerrarModalEditar}>
        {bodyEditar}
      </Modal>


    </>
  );
};

export default ShoppingCart;