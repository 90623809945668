import React from 'react';
import logoPC from '../assets/img/logo-nuban.png';
import iconMobile from '../assets/img/logo.png';

function LogoComponent() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const logoImage = isMobile ? iconMobile : logoPC;

  return (
    <img src={logoImage} alt="Logo de mi app" />
  );
}

export default LogoComponent;
