import AuthService from "./auth.service";

const MAX_RETRIES = 3; // Número máximo de reintentos

export function handleResponse(response, retries = 0) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        AuthService.logout();
        //location.reload(true);
      }

      const error = (data && data.message) || response.statusText;

      // Verificar si se deben hacer más reintentos
      if (retries < MAX_RETRIES) {
        console.error(`Intento ${retries + 1} fallido. Reintentando...`, error);
        return new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
          // Realizar un nuevo intento
          return handleResponse(response, retries + 1);
        });
      }

      // Si se supera el número máximo de reintentos, rechazar con el error
      return Promise.reject(error);
    }

    return data;
  });
}
