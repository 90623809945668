import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserService from "../services/user.service";

const OfflineRedirect = () => {
    const history = useHistory();

    useEffect(() => {
        const GetOfflineOnline = async () => {
            try {
                const result = await UserService.getOfflineOnline();
                if (result.data[0].estado === "0" &&
                    window.location.pathname !== "/adminhome" &&
                    window.location.pathname !== "/admindescuentos" &&
                    window.location.pathname !== "/novedades" &&
                    window.location.pathname !== "/adminnoticias" &&
                    window.location.pathname !== "/adminclientes" &&
                    window.location.pathname !== "/adminarticulos" &&
                    window.location.pathname !== "/adminofflineonline"
                ) {
                    //    if (result.data[0].estado === "0") {
                    // Redirige a la página "Offline"
                    history.push("/offline");
                }
            } catch (e) {
                console.log(e);
            }
        }
        GetOfflineOnline();
    }, [history]);

    return null; // Este HOC no renderiza nada
};

export default OfflineRedirect;
