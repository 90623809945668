/**
 * App Mine
 * update 10/2022
 * By Sergio Sam 
 */


import React, { useState, useEffect } from "react";

import { Switch, Route } from "react-router-dom";

import Home from "./components/FrontEnd_Home.component";
import Login from "./components/Login";
//import Register from "./components/FrontEnd_Register_turnos.component";
import AdminHome from "./components/Admin_Home.component";
import AdminNoticias from "./components/Admin_Noticias.component";
import AdminTextos from "./components/Admin_Textos.component";
import AdminClientes from "./components/Admin_Clientes.component";
import AdminDescuentos from "./components/Admin_Descuentos.component";
import AdminOfflineOnline from "./components/Admin_OfflineOnline.component";
import AdminArticulos from "./components/Admin_Articulos.component";
import AdminDescuentosMod from "./components/Admin_DescuentosMod.component";
import AdminNovedadesAddMod from "./components/Admin_NovedadesAddMod.component";

import AdminAdministradores from "./components/Admin_Administradores.component";
import AdminAdministradoresAddMod from "./components/Admin_AdministradoresAddMod.component";

import Offline from "./components/FrontEnd_Offline";
import ShoppingCart from "./components/FrontEnd_ShoppingCart.component";
import PedidoExpress from "./components/FrontEnd_PedidoExpress.component";
import Cart from "./components/FrontEnd_Cart.component";
import Pedido from "./components/FrontEnd_Pedido.component";
import Pedido0 from "./components/FrontEnd_Pedido0.component";
import Pedido1 from "./components/FrontEnd_Pedido1.component";
import HistorialPedidos from "./components/FrontEnd_Historial_Pedidos.component";
import RecuperarPedidos from "./components/FrontEnd_Recuperar_Pedidos.components";
import Articulos from "./components/FrontEnd_Articulos.component";
import Listas from "./components/FrontEnd_Listas.component";
import Catalogos from "./components/FrontEnd_Catalogos.component";
import DatosUtiles from "./components/FrontEnd_DatosUtiles.component";
import Profile from "./components/FrontEnd_Profile.component";
import Reclamos from "./components/FrontEnd_Reclamos.component";
import Direccion from "./components/FrontEnd_Direccion.component";
import Gracias from "./components/FrontEnd_Gracias";
import GraciasNota from "./components/FrontEnd_GraciasNota";
import Ayuda from "./components/FrontEnd_Ayuda";
import Instructivos from "./components/FrontEnd_Instructivos";
import Recuperado from "./components/FrontEnd_Recuperado";
import Bancos from "./components/FrontEnd_Bancos";
import Reparar from "./components/FrontEnd_Reparar";
import CartHistorial from "./components/FrontEnd_Cart_Historial.component";
import Novedades from "./components/Admin_Novedades.component";

import Categorias from "./components/FrontEnd_Categorias.component";
import OfflineRedirect from "./components/OfflineRedirect"; // Importa el componente OfflineRedirect

import { IntlProvider } from 'react-intl';
import { messages as allMessages } from './messages';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = (props) => {
  const currentLocale = 'es';
  const messages = allMessages[currentLocale];

  return (
    <IntlProvider locale={currentLocale} messages={messages}>
      <ToastContainer />
      <OfflineRedirect />  {/* Incluye el componente OfflineRedirect aquí */}
      <Switch>
        <Route exact path={["/", "/home"]} component={Home} />
        <Route exact path={"/offline"} component={Offline} />
        <Route exact path={"/home"} component={Home} />
        <Route exact path={"/listas"} component={Listas} />
        <Route exact path={"/catalogos"} component={Catalogos} />
        <Route exact path={"/datosutiles"} component={DatosUtiles} />
        <Route exact path={"/profile"} component={Profile} />
        <Route exact path={"/adminofflineonline"} component={AdminOfflineOnline} />
        <Route exact path={"/adminhome"} component={AdminHome} />
        <Route exact path={"/adminnoticias"} component={AdminNoticias} />
        <Route exact path={"/admintextos"} component={AdminTextos} />
        <Route exact path={"/adminclientes"} component={AdminClientes} />
        <Route exact path={"/admindescuentos"} component={AdminDescuentos} />
        <Route exact path={"/adminarticulos"} component={AdminArticulos} />
        <Route exact path={"/descuentosmod/:id"} component={AdminDescuentosMod} />
        <Route exact path={"/shoppingcart"} component={ShoppingCart} />
        <Route exact path={"/cart"} component={Cart} />
        <Route exact path={"/categorias"} component={Categorias} />
        <Route exact path={"/categoriaprincipal/:id"} component={Pedido0} />
        <Route exact path={"/pedidoexpress"} component={PedidoExpress} />
        <Route exact path={"/pedido"} component={Pedido} />
        <Route exact path={"/pedido1/:id"} component={Pedido1} />
        <Route exact path={"/articulos/:id"} component={Articulos} />
        <Route exact path={"/historial"} component={HistorialPedidos} />
        <Route exact path={"/recuperar"} component={RecuperarPedidos} />
        <Route exact path={"/carthistorial/:id"} component={CartHistorial} />
        <Route exact path={"/direccion"} component={Direccion} />
        <Route exact path={"/gracias"} component={Gracias} />
        <Route exact path={"/graciasnota"} component={GraciasNota} />
        <Route exact path={"/ayuda"} component={Ayuda} />
        <Route exact path={"/instructivos"} component={Instructivos} />
        <Route exact path={"/bancos"} component={Bancos} />
        <Route exact path={"/novedades"} component={Novedades} />
        <Route exact path={"/novedadesaddmod"} component={AdminNovedadesAddMod} />
        <Route exact path={"/novedadesaddmod/:id"} component={AdminNovedadesAddMod} />
        <Route exact path={"/recuperado"} component={Recuperado} />
        <Route exact path={"/reclamos"} component={Reclamos} />
        <Route exact path={"/reparar"} component={Reparar} />
        <Route exact path="/logout" component={Login} />
        <Route exact path="/login" component={Login} />
        {/*<Route exact path="/register" component={Register} />*/}
        <Route exact path={"/administradores"} component={AdminAdministradores} />
        <Route exact path={"/administradoresaddmod"} component={AdminAdministradoresAddMod} />
        <Route exact path={"/administradoresaddmod/:id"} component={AdminAdministradoresAddMod} />
        <Route component={Home} />
      </Switch>
    </IntlProvider>
  );
};

export default App;
