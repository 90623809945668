import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { clear_cart, add_to_cart, init_to_prod, init_to_prod_quantity } from "../reducers/shoppingSlice";
import { useSelector, useDispatch } from "react-redux";
import ProductItemRecuperar from "./FrontEnd_ProductItemRecuperar";

//const MenuSubCategorias = (props) => {
const FrontEndRecuperar = ({ tipo, addToCart }) => {

    const [data, setData] = useState([]);

    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const { products, cart } = state;

    useEffect(() => {

        //dispatch(clear_cart());

        const GetDatos = async () => {
            try {

                //Busca articulos == 0
                const result = await UserService.getRecuperarPedidos();
                if (result.data) {
                    dispatch(init_to_prod(result.data));
                    //dispatch(init_to_prod_quantity());
                    setData(result.data);
                } else {
                }
            } catch (e) {
                console.log(e);
            }
        }
        GetDatos();



    }, []);

    return (
        <>
            {
                data.map((product) => (
                    <ProductItemRecuperar
                        key={product.id}
                        data={product}
                        addToCart={() => dispatch(add_to_cart(product.id))}
                    />
                ))
            }
        </>
    );
}

export default FrontEndRecuperar;

