import React, { useState, useRef } from "react";
import AuthService from "../services/auth.service";
import * as yup from 'yup';
import { useFormik } from 'formik';

import { useSelector, useDispatch } from "react-redux";
import { clear_cart } from "../reducers/shoppingSlice";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import SnackbarMessage from './SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage
import logoPC from '../assets/img/logo-nuban.png';

import packageJson from '../../package.json';

//Validacion del formulario
const validationSchema = yup.object({
  _username: yup
    .string('Email requerido')
    //.email('Email no valido')
    .required('Email requerido'),
  _password: yup.string()
    .required('Password es requerido')
    .min(6, 'Password debe tener al menos 6 caracteres')
    .max(40, 'Password no debe exeder los 40 caracteres'),
});

export default function Login(props) {
  const [shown, setShown] = useState(false);
  const switchShown = () => setShown(!shown);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { username } = state;
  const appVersion = packageJson.version;

  //Para el uso del componente de mensajes 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleShowMessage = (message) => {
    setErrorMessage(message);
    setOpen(true);
  };
  const handleCloseMessage = () => {
    setOpen(false);
  };
  //Fin

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      _username: '',
      _password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
    },
  });

  const peticionPost = async (values) => {
    try {
      await AuthService.login(values._username, values._password);
      const data = JSON.parse(localStorage.getItem("user"));
      if (data.username !== username) {
        dispatch(clear_cart());
        cerrarEditar();
      } else {
        cerrarEditar();
      }
    } catch (error) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // Verifica el mensaje de error y muestra un mensaje personalizado en su lugar
      if (resMessage === 'Invalid credentials.') {
        handleShowMessage('Login');
      }

    }
  };

  const cerrarEditar = () => {
      props.history.push("/home");
      window.location.reload();
  }

  return (

    <>
      <main>
        <div class="container">

          <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                  <div class="d-flex justify-content-center py-4">
                    <a href="index.html" class="logo d-flex align-items-center w-auto">
                      <img src={logoPC} alt="" />

                    </a>
                  </div>

                  <div class="card mb-3">

                    <div class="card-body">

                      <div class="pt-4 pb-2">
                        <h5 class="card-title text-center pb-0 fs-4">Sitio exclusivo<br />para Distribuidores</h5>
                        <p class="text-center small">Ingresá con tu usuario y contraseña</p>
                      </div>

                      <form class="row g-3 needs-validation" onSubmit={formik.handleSubmit}>

                        <div class="col-12">
                          <label for="yourUsername" class="form-label">Usuario</label>
                          <div class="input-group has-validation">
                            <input type="text" name="_username" class="form-control"
                              value={formik.values._username}
                              onChange={formik.handleChange}
                              error={formik.touched._username && Boolean(formik.errors._username)}
                              helperText={formik.touched._username && formik.errors._username}
                            />

                            {formik.errors._username &&
                              <div>
                                {formik.errors._username}
                              </div>}
                          </div>
                        </div>

                        <div class="col-12">
                          <label for="yourPassword" class="form-label">Contraseña</label>
                          <div className="password-input">
                            <input
                              type={shown ? 'text' : 'password'}
                              name="_password"
                              className="form-control"
                              value={formik.values._password}
                              onChange={formik.handleChange}
                              error={formik.touched._password && Boolean(formik.errors._password)}
                              helperText={formik.touched._password && formik.errors._password}
                            />

                            <span className="password-toggle" onClick={switchShown}>
                              {shown ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </span>

                          </div>

                          {formik.errors._password &&
                            <div>
                              {formik.errors._password}
                            </div>}

                        </div>

                        <div class="col-12">
                          <button class="btn btn-primary w-100" type="submit">Ingresar</button>
                        </div>

                        <div class="col-12">
                          <input type="hidden" name="producto" class="form-control"
                            value={formik.values.producto}
                            onChange={formik.handleChange}
                            error={formik.touched.producto && Boolean(formik.errors.producto)}
                            helperText={formik.touched.producto && formik.errors.producto}
                          />
                        </div>

                      </form>

                    </div>

                  </div>

                  <div class="credits">
                    <a href="https://industriabasica.ar/" target="_blank">INDUSTRIA BASICA S.A.</a>
                  </div>

                </div>
              </div>
            </div>

            <footer>
              <br></br>
              <p className="version-text">Versión de la aplicación: {appVersion}</p>
            </footer>

          </section>


        </div>

        <SnackbarMessage
          open={open}
          message={errorMessage}
          severity="success"
          onClose={handleCloseMessage}
        />

      </main>
    </>
  );
}
