import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import AuthService from "../services/auth.service";

import UserService from "../services/user.service";
import MyButton from "../components/Alert.component";

const initialState = {
  products: [],
  cart: [],
  moneda: 'USD', //moneda
  username: '', //nombre del usuario
  iddireccion: '',//id de la direccion por derecto o unica 
  orden_compra: '',//orden de compra 
  customer_note: '',//nota 
  cantidad: 0,//cantidad 
  minimo: 0,//minimo 
};

const user = AuthService.getCurrentUser();

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  'counter/fetchCount',
  async (amount) => {
    const response = '';
    //const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

const nada = ''
let i = ''
let cant = 0;
export const shoppingSlice = createSlice({
  name: 'store',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    init_to_prod: (state, action) => {
      state.products = action.payload;
      state.username = user.username;
    },

    init_to_dir: (state, action) => {
      state.iddireccion = action.payload;
    },

    init_to_min: (state, action) => {
      state.minimo = action.payload;
    },

    init_to_orden_compra: (state, action) => {
      state.orden_compra = action.payload;
    },

    init_to_customer_note: (state, action) => {
      state.customer_note = action.payload;
    },

    init_to_prod_quantity: (state, action) => {
      return {
        ...state,
        products: state.products.map((item) =>
          state.cart.find((it) => it.id === item.id)
            ? { ...item, quantity: state.cart.find((it) => it.id === item.id) }
            : { ...item }
        ),
      }

    },

    init_to_cart: (state, action) => {
      initialState.cart = action.payload
      state.username = user.username;
    },

    add_quantity: (state, action) => {
      cant = 0;
      //state.cantidad = action.payload.quantity;
      //state.cart.map((item) => (
      //  state.cantidad=state.cantidad+item.quantity
      //)),

      state.cart.map((item, index) => (
        cant = Number(cant) + Number(item.quantity),
        state.cantidad = cant
      ))

    },

    add_to_cart_quantity: (state, action) => {
      let newItem = state.products.find(
        (product) => product.id === action.payload.id
      );

      let itemInCart = state.cart.find((item) => item.id === newItem.id);

      return itemInCart
        ? {
          ...state,
          cart: state.cart.map((item) =>
            item.id === newItem.id
              ? { ...item, quantity: action.payload.quantity }
              : item
          ),
          products: state.products.map((item) =>
            item.id === newItem.id
              ? { ...item, quantity: action.payload.quantity }
              : item
          ),
        }
        : {
          ...state,
          cart: [...state.cart, { ...newItem, quantity: action.payload.quantity }],
          products: state.products.map((item) =>
            item.id === newItem.id
              ? { ...item, quantity: action.payload.quantity }
              : item
          ),
        };
    },

    add_to_cart: (state, action) => {
      let newItem = state.products.find(
        (product) => product.id === action.payload
      );

      let itemInCart = state.cart.find((item) => item.id === newItem.id);

      return itemInCart
        ? {
          ...state,
          cart: state.cart.map((item) =>
            item.id === newItem.id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          ),
        }
        : {
          ...state,
          cart: [...state.cart, { ...newItem, quantity: 1 }],
        };
    },

    add_to_cart_recuperar: (state, action) => {

      let newItem = state.products.find(
        (product) => product.product_sku === action.payload
      );

      let itemInCart = state.cart.find((item) => item.product_sku === newItem.product_sku);

      return itemInCart
        ? {
          ...state,
          cart: state.cart.map((item) =>
            item.id === newItem.id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          ),
        }
        : {
          ...state,
          cart: [...state.cart, { ...newItem, quantity: 1 }],
        };
    },

    remove_one_from_cart1: (state, action) => {
      const updatedCart = state.cart.filter((item) => item.id !== action.payload);
      const updatedCantidad = updatedCart.reduce((total, item) => Number(total) + Number(item.quantity), 0);
    
      return {
        ...state,
        cart: updatedCart,
        cantidad: updatedCantidad, // Actualiza la cantidad total de items en el carrito
      };
    },
      
    remove_one_from_cart: (state, action) => {
      const productId = action.payload;
      const updatedCart = state.cart.filter((item) => item.id !== productId);
      const updatedCantidad = updatedCart.reduce(
        (total, item) => Number(total) + Number(item.quantity),
        0
      );
    
      // Actualiza la cantidad del producto a cero en el estado de Redux
      const updatedProducts = state.products.map((item) =>
        item.id === productId ? { ...item, quantity: 0 } : item
      );
    
      return {
        ...state,
        products: updatedProducts,
        cart: updatedCart,
        cantidad: updatedCantidad, // Actualiza la cantidad total de items en el carrito
      };
    },
            
    remove_all_from_cart: (state, action) => {
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload),
      };
    },

    clear_cart: (state, action) => {
      return initialState;
      //return { ...initialState };
    },

    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },

    decrement: (state) => {
      state.value -= 1;
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },

  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value += action.payload;
      });
  },

});

export const { add_quantity, init_to_prod, init_to_cart, init_to_prod_quantity, init_to_dir, init_to_min, add_to_cart, add_to_cart_recuperar, add_to_cart_quantity, clear_cart, remove_one_from_cart, remove_all_from_cart, increment, decrement, incrementByAmount, init_to_orden_compra, init_to_customer_note } = shoppingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default shoppingSlice.reducer;