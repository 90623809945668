import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import { makeStyles } from '@mui/styles';

import InputLabel from '@mui/material/InputLabel';
import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import AdminSidebar from "./Admin_Sidebar";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

import Paper from '@mui/material/Paper';
import { Modal, Button } from '@mui/material';

import {
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";

import MaterialTable from 'material-table';

let direction = "ltr";

const theme = createTheme(
    adaptV4Theme({
        direction: direction,
        palette: {
            mode: "light",
        },
    })
);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        position: 'absolute',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));


export default function AdminOfflineOnline(props) {

    const styles = useStyles();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [estado, setEstado] = useState(0);
    const [ade, setAde] = useState(0);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEditar1, setModalEditar1] = useState(false);

    useEffect(() => {

        const GetOfflineOnline = async () => {
            try {
                const result = await UserService.getOfflineOnline();
                if (result.data[0].estado) {
                    setEstado(result.data[0]);
                } else {
                    // props.history.push("/login");
                }
            } catch (e) {
                console.log(e);
                props.history.push("/login");
            }
        }
        GetOfflineOnline();


    }, []);

    const abrirCerrarModalEditar = (id) => {
        getOfflineOnline(id);
    }


    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const abrirCerrarModalEliminar1 = () => {
        setModalEliminar(!modalEliminar);
    }

    const getOfflineOnline = async (id) => {
        console.log(id)
        if (id) {
            const response = await UserService.getOfflineOnline();
        } else {
        }
        setModalEditar(!modalEditar);
        //getDirecciones();
    }

    const peticionPost = async (estado) => {
        // Intercambiar los valores 0 y 1
        const nuevoEstado = estado === "0" ? "1" : "0";

        const response = await UserService.addmodOfflineOnline(nuevoEstado);
        if (response) {
            console.log(response.data)
            setEstado(response.data[0]);
        }
        abrirCerrarModalEditar()
    }


    return (
        <>
            <Header />
            <AdminSidebar />
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>Offline/Onlie</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Inicio</a></li>
                        </ol>
                    </nav>
                </div>

                <section class="section">
                    <div class="row">
                        <div class="col-lg-12">


                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Declarar el sitio Offline o Online </h5>

                                    <Paper className={classes.root}>
                                        <div class="text-left">
                                            <button onClick={() => peticionPost(estado.estado)} class="btn btn-primary">
                                                Cambiar estado, estado actual: [{estado.estado === "0" ? 'Offline' : 'Online'}]
                                            </button>
                                        </div>
                                    </Paper>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>

            </main>


            <Footer />
        </>

    );
}

//export default Home;
