import React, { useState, useEffect } from "react";

import AuthService from "../services/auth.service";
import FrontEndCartNumero from "./FrontEnd_CartNumero";
import FrontEndCartNovedades from "./FrontEnd_NovedadesNumero";
import LogoComponent from "./FrontEnd_Logo";
import UserService from "../services/user.service";

import { useHistory } from "react-router-dom";

const FrontEndHeader = () => {
    const history = useHistory();
    const [nombre, setNombre] = useState([]);

    const [currentUser, setCurrentUser] = useState({
        username: '',
    });

    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);

    const handleDrawerClose = () => {
        document.body.classList.toggle("toggle-sidebar");
    };

    useEffect(() => {

        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
            setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
            setShowAdminBoard(user.roles.includes("ROLE_ADMINISTRADORES") || user.roles.includes("ROLE_MASTER"));
        } else {
            history.push("/login");
        }

    }, []);

    useEffect(() => {

        const GetNombre = async () => {
            try {

                const result = await UserService.getNombre();
                if (result.data) {
                    setNombre(result.data[0].nombre);
                } else {
                    console.log('no hay datos')
                }
            } catch (e) {
                console.log(e);
            }
        }
        GetNombre();

    }, []);


    const handleLogOut = (event) => {
        event.preventDefault();

        AuthService.logout();
        history.push("/login");
    };
    const cart = () => {

        history.push(process.env.PUBLIC_URL + "/cart");
    }

    return (

        <header id="header" className="header fixed-top d-flex align-items-center">

            <div className="d-flex align-items-center justify-content-between">
                <a href="/" className="logo d-flex align-items-center">
                    <LogoComponent />
                </a>
                <i className="bi bi-list toggle-sidebar-btn" onClick={handleDrawerClose}></i>
            </div>

            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">

                    <li className="nav-item dropdown">

                        <FrontEndCartNovedades />
                    </li>

                    <li className="nav-item dropdown">

                        <a className="nav-link nav-icon" onClick={() => cart()} >
                            <FrontEndCartNumero />
                        </a>

                    </li>

                    <li className="nav-item dropdown pe-3">

                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            <i className="ri-shield-user-fill" style={{ fontSize: '24px' }}></i>

                            <span className="d-none d-md-block dropdown-toggle ps-2">{nombre}</span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6></h6>
                                <span>Distribuidor</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            {showAdminBoard && (
                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="/adminhome">
                                        <i className="ri-shield-user-fill"></i>
                                        <span>ADMINISTRADOR</span>
                                    </a>
                                </li>
                            )}

                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="/profile">
                                    <i className="bi bi-person"></i>
                                    <span>Mi Perfil</span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="/ayuda">
                                    <i className="bi bi-question-circle"></i>
                                    <span>Necesitas Ayuda ?</span>
                                </a>
                            </li>

                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="/instructivos">
                                    <i className="bi bi-question-circle"></i>
                                    <span>Instructivos</span>
                                </a>
                            </li>

                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            <li onClick={handleLogOut} style={{ cursor: 'pointer' }}>
                                <a className="dropdown-item d-flex align-items-center">
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span>Salir</span>
                                </a>
                            </li>

                        </ul>
                    </li>

                </ul>
            </nav>

        </header>

    );
}

export default FrontEndHeader;