import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import { useHistory } from "react-router-dom";

const FrontEndNovedadesNumero = (props) => {
    const state = useSelector((state) => state);
    const { cantidad } = state;

    const history = useHistory();

    const [currentUser, setCurrentUser] = useState({
        username: '',
    });

    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [data, setData] = useState([]);
    const [dato, setDato] = useState([]);
    let total = 0;
    const [numero, setNumero] = useState(0);

    useEffect(() => {



    }, []);

    const leernotificacion = async () => {
        try {
            const result = await UserService.getNovedadesActivas();
            if (result.data) {
                setDato(result.data);
                setData(result.data[0]);
            } else {
                //props.history.push("/login");
            }
        } catch (e) {
            console.log(e);
        }
    }

    const leer = async (id) => {
        try {
            const response = await UserService.readNovedad(id);
            leernotificacion();
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                <i class="bi bi-bell"></i>
                <span class="badge bg-primary badge-number">{data.total}</span>
            </a>

            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                <li class="dropdown-header">
                    Tienes {data.total} notificaciones nuevas
                </li>
                <li>
                    <hr class="dropdown-divider" />
                </li>
                {
                    data.total > 0 &&
                    dato.map((item, index) => (
                        <>
                            <li class="notification-item">
                                <i class="bi bi-exclamation-circle text-warning"></i>
                                <div>
                                    <h4>{item.nombre}</h4>
                                    <p>{item.texto}</p>
                                </div>
                                <i class="bi bi-x-circle text-warning" onClick={() => leer(item.id)} ></i>
                            </li>

                            <li>
                                <hr class="dropdown-divider" />
                            </li>
                        </>
                    ))}

            </ul>

        </>
    );
}

export default FrontEndNovedadesNumero;