import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { add_to_cart, init_to_prod, init_to_prod_quantity } from "../reducers/shoppingSlice";
import { useSelector, useDispatch } from "react-redux";
import ProductItemHistorial from "./FrontEnd_ProductItemHistorial";
import SnackbarMessage from './SnackbarMessage'; // Asegúrate de proporcionar la ruta correcta al archivo SnackbarMessage

//const MenuSubCategorias = (props) => {
const FrontEndHistorial = ({ tipo, addToCart }) => {

    const [data, setData] = useState([]);

    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const { products, cart } = state;

    useEffect(() => {

        const GetDatos = async () => {
            try {

                //Busca articulos == 0
                const result = await UserService.getHistorialPedidos();
                if (result.data) {
                    dispatch(init_to_prod(result.data));
                    dispatch(init_to_prod_quantity());
                    setData(result.data);
                } else {
                    //props.history.push("/login");
                }
            } catch (e) {
                console.log(e);
                handleShowMessage('<div><h4>Error al cargar los datos.<br/>Es posible que haya un problema con la conexión a Internet o con el servidor.<br/> Para recargar la página, puedes presionar F5 en tu computadora o deslizar la pantalla hacia abajo en tu celular.<br/> Si el problema persiste, por favor, inténtalo de nuevo más tarde o contacta al soporte técnico.</h4></div>');
            }
        }
        GetDatos();

    }, []);

    //Para el uso del componente de mensajes 
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowMessage = (message) => {
        setErrorMessage(message);
        setOpen(true);
    };
    const handleCloseMessage = () => {
        setOpen(false);
    };
    //Fin

    return (
        <>
            {
                products.map((product) => (
                    <ProductItemHistorial
                        key={product.id}
                        data={product}
                        addToCart={() => dispatch(add_to_cart(product.id))}
                    />
                ))
            }

            <SnackbarMessage
                open={open}
                message={errorMessage}
                severity="success"
                onClose={handleCloseMessage}
            />

        </>
    );
}

export default FrontEndHistorial;

