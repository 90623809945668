import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import FrontEndHeader from "./FrontEnd_Header";
import FrontEndFooter from "./FrontEnd_Footer";
import FrontEndSidebar from "./FrontEnd_Sidebar";

const FrontEndRecuperado = () => {
    const history = useHistory();

    const [currentUser, setCurrentUser] = useState({
        username: '',
    }); 

    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);

    useEffect(() => {
      //history.push(process.env.PUBLIC_URL + "/cart");

    }, []);


    const cart = () => {
        history.push(process.env.PUBLIC_URL + "/cart");
    }

    return (
        
        <>
        <FrontEndHeader />
        <FrontEndSidebar />
        <main id="main" class="main">
  
          <div class="pagetitle">
            <h1>PEDIDO RECUPERADO</h1>
          </div>
  
          <section class="section contact">
  
            <div class="row gy-4">
  
              <div class="col-xl-12">
  
                <div class="row">
  
                  <div class="col-lg-12">
                    <div class="info-box card">
                      <i class="ri-file-copy-line"></i>
                      <h3>
                        Tu pedido fue recuperado y cargado al carrito de compras, podes seguir comprando desde "realizar pedido/ pedido express" o finalizar la compra haciendo clic en el icono del carrito de compras
                        <br></br>
                        <br></br>
                        <button onClick={() => cart()} class="btn btn-primary">CONTINUAR</button>
                      </h3>
                      <p></p>
                    </div>
                  </div>
                </div>
  
              </div>
            </div>
          </section>
  
        </main>
  
        <FrontEndFooter />
      </>


    );
}

export default FrontEndRecuperado;