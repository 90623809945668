import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";

import { Button, TextField } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import { makeStyles } from '@mui/styles';

import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import AdminSidebar from "./Admin_Sidebar";
import AuthService from "../services/auth.service";
import { Modal } from '@mui/material';
import { useParams } from 'react-router';
import { FormLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";
import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
  nombre: yup
    .string('Titulo requerido')
    .required('Titulo requerido'),
});

let direction = "ltr";

const theme = createTheme(
  adaptV4Theme({
    direction: direction,
    palette: {
      mode: "light",
    },
  })
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: 5,
    padding: (2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconos: {
    cursor: 'pointer'
  },
  inputMaterial: {
    width: '100%'
  }
}));

export default function AdminNovedadesAddMod(props) {

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      nombre: '',
      texto: '',
      activo: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
      //alert(JSON.stringify(values, null, 2));
    },
  });

  const [isLoading, setIsLoading] = useState(false)
  const styles = useStyles();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    id: '',
    nombre: '',
    codigo: '',
  })
  const { id } = useParams();

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    //console.log(consola.id);
    (caso === 'Editar') ? props.history.push(process.env.PUBLIC_URL + "/vehiculosmod/" + consola.id) : abrirCerrarModalEliminar()
  }
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const peticionDelete = async () => {
    const response = await UserService.delTexto(consolaSeleccionada.id);
    var data = response.data;
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const peticionGet = async () => {
    const result = await UserService.getTextos();
    setData(result.data);
  }

  useEffect(() => {

    const user = AuthService.getCurrentUser();
    if (user) {
      setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMININSTRADORES"));
    } else {
      props.history.push("/login");
    }

    const GetData = async () => {
      try {
        const response = await UserService.getNovedad(id);

        if (response) {
          formik.setValues(response);
        } else {
          props.history.push(process.env.PUBLIC_URL + "/login");
        }
      } catch (e) {
        props.history.push(process.env.PUBLIC_URL + "/login");
      }
    }
    GetData();
  }, []);

  const inicio = async () => {
    props.history.push("/");
  }

  const peticionPost = async (values) => {
    const response = await UserService.addmodNovedad(id, values);
    ///setData(data.concat(response.data))
    cerrarEditar()
  }

  const cerrarEditar = () => {
    props.history.push(process.env.PUBLIC_URL + "/novedades");
    //peticionGet();

  }

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar el Texto <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  return (
    <>
      <Header />
      <AdminSidebar />
      <main id="main" class="main">

        <div class="pagetitle">
          <h1>NUEVA NOVEDAD</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Inicio</a></li>
            </ol>
          </nav>
        </div>

        <section class="section contact">

          <div class="row gy-4">

            <div class="col-xl-12">

              <div class="row">

                <div class="col-lg-12">

                  <div class="tab-content pt-2">

                    <div class="tab-pane show active fade pt-3" id="profile-settings">

                      <div class="row">


                        <form onSubmit={formik.handleSubmit}>

                          <Grid container spacing={1} style={{ minHeight: '100vh', padding: '5px' }} >

                            <Grid item xs={12}>
                              <TextField
                                name="nombre"
                                className={styles.inputMaterial}
                                label="Titulo de la novedad"
                                autoFocus={true}
                                value={formik.values.nombre}
                                autoComplete='off'
                                onChange={formik.handleChange}
                                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                helperText={formik.touched.nombre && formik.errors.nombre}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextareaAutosize
                                name="texto"
                                aria-label="empty textarea"
                                className={styles.inputMaterial}
                                minRows={3} label="Texto"
                                placeholder="Texto"
                                value={formik.values.texto}
                                onChange={formik.handleChange}
                                error={formik.touched.texto && Boolean(formik.errors.texto)}
                                helperText={formik.touched.texto && formik.errors.texto}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <div class="col-lg-12" style={{ textAlign: "right" }} >
                                <Button color="primary" type="submit" class="btn btn-primary">Guardar</Button>
                              </div>
                            </Grid>
                          </Grid>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>
      <Footer />

    </>
  );
}
