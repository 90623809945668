import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { NumericFormat } from 'react-number-format';

const FrontEndCartItemHistorial = ({ data, delFromCart }) => {
  let { id, product_sku, name, price, quantity } = data;
 
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { moneda } = state;

  return (
    <tr>
      <td scope="row">
        {product_sku}
      </td>
      <td scope="row">
        {name}
      </td>
      <td>
        {moneda} {price}
      </td>
      <td>
        {quantity}
      </td>
      <td>
        {moneda} <NumericFormat value={price * quantity} decimalScale={2} displayType={'text'} />
      </td>

      <td class="fw-bold">
      </td>
      
    </tr>
  );
};

export default FrontEndCartItemHistorial;