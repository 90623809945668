import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import { makeStyles } from '@mui/styles';

import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import AdminSidebar from "./Admin_Sidebar";

import Paper from '@mui/material/Paper';
import { Modal, Button } from '@mui/material';
import { useHistory } from "react-router-dom";

import {
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";

import MaterialTable from 'material-table';

let direction = "ltr";

const theme = createTheme(
    adaptV4Theme({
        direction: direction,
        palette: {
            mode: "light",
        },
    })
);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        position: 'absolute',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));

export default function AdminNovedades(props) {
    const styles = useStyles();
    const history = useHistory();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [consolaSeleccionada, setConsolaSeleccionada] = useState({
        nombre: '',
        codigo: '',
    })
    const [modalEliminar, setModalEliminar] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
            setShowAdminBoard(user.roles.includes("ROLE_ADMININSTRADORES"));
        }

        const GetData = async () => {
            try {
                const result = await UserService.getNovedades();
                if (result.data) {
                    //const res = JSON.parse(result.data);

                    console.log(result.data);
                    setData(result.data);
                } else {
                    props.history.push("/login");
                }
            } catch (e) {
                console.log(e);
                props.history.push("/login");
            }
        }
        GetData();


    }, []);

    const nueva = async () => {
        props.history.push(process.env.PUBLIC_URL + "/novedadesaddmod")
    }

    const seleccionarConsola = (consola, caso) => {
        setConsolaSeleccionada(consola);
        (caso === 'Editar') ? history.push(process.env.PUBLIC_URL + "/novedadesaddmod/" + consola.id) : abrirCerrarModalEliminar()
    }

    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const bodyEliminar = (
        <div className={styles.modal}>
            <p>Estás seguro que deseas eliminar la Novedad <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
            <div align="right">
                <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
                <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
            </div>
        </div>
    )

    const peticionDelete = async () => {
        const res = await UserService.delNovedad(consolaSeleccionada.id);

        if (res.data[0].error) {
            setShowAlert(true)
            abrirCerrarModalEliminar();
        } else {
            setShowAlert(false)
            peticionGet();
            abrirCerrarModalEliminar();
        }

    }

    const peticionGet = async () => {
        const result = await UserService.getNovedades();
        setData(result.data);
    }

    return (
        <>
            <Header />
            <AdminSidebar />
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>NOVEDADES</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Inicio</a></li>
                        </ol>
                    </nav>
                </div>

                <section class="section">
                    <div class="row">
                        <div class="col-lg-12">

                            <div class="card">
                                <div class="card-body">

                                    <Paper className={classes.root}>

                                        <br />
                                        <Button style={{ color: "#fff", backgroundColor: "rgb(0, 79, 158)" }} variant="contained" onClick={() => nueva()}>Nueva Novedad</Button>
                                        <br /><br />

                                        <StyledEngineProvider injectFirst>
                                            <ThemeProvider theme={theme}>
                                                <div style={{ maxWidth: "100%", direction }}>
                                                    <Grid container>
                                                        <Grid item xs={12}>

                                                            <MaterialTable
                                                                title=""

                                                                localization={{
                                                                    toolbar: {
                                                                        searchPlaceholder: 'Buscar...'
                                                                    },
                                                                    header: {
                                                                        actions: 'Acciones'
                                                                    },
                                                                }}

                                                                columns={[
                                                                    {
                                                                        title: 'Titulo',
                                                                        field: 'nombre',
                                                                    },
                                                                    {
                                                                        title: 'Activo',
                                                                        field: 'activo',
                                                                    },
                                                                ]}
                                                                data={data}
                                                                actions={[
                                                                    //{
                                                                    //    icon: 'edit',
                                                                    //    tooltip: 'Editar',
                                                                    //    onClick: (event, data) => seleccionarConsola(data, 'Editar'),
                                                                    //},
                                                                    {
                                                                        icon: 'delete',
                                                                        tooltip: 'Eliminar',
                                                                        onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
                                                                    }
                                                                ]}
                                                                options={{
                                                                    headerStyle: {
                                                                        backgroundColor: '#004F9E',
                                                                        color: '#FFF',
                                                                    },
                                                                    search: true,
                                                                    actionsColumnIndex: -1
                                                                }}
                                                            />

                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </ThemeProvider>
                                        </StyledEngineProvider>

                                    </Paper>


                                </div>
                            </div>

                        </div>

                    </div>
                </section>


                <Modal
                    open={modalEliminar}
                    onClose={abrirCerrarModalEliminar}>
                    {bodyEliminar}
                </Modal>

            </main>
            <Footer />
        </>

    );
}


//export default Home;
