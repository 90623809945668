import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import Sidebar from "./FrontEnd_Sidebar";
import { useHistory } from "react-router-dom";
import { init_to_prod_quantity, init_to_prod, clear_cart, remove_one_from_cart, init_to_orden_compra, init_to_customer_note } from "../reducers/shoppingSlice";
import Total from "./FrontEnd_Total.component";
import CartItem from "./FrontEnd_CartItem.component";
import FooterText from "./FrontEnd_FooterText";
import TextField from '@mui/material/TextField';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import { createSerializableStateInvariantMiddleware } from "@reduxjs/toolkit";

const validationSchema = yup.object().shape({
  customer_note: yup.string()
    .matches(/^[a-zA-ZñÑ0-9\s;.,+]+$/, 'Solo se permiten letras, números, punto, coma y el signo +'),
});

const ShoppingCart = () => {

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      orden_compra: '',
      customer_note: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(cart, values);
    },
  });

  const history = useHistory();

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { cart, moneda, cantidad } = state;

  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const [vacio, setVacio] = useState(true);

  // Función para redireccionar al home cuando el carrito quede vacío
  const redirectToHomeIfEmpty = () => {
    if (cart.length === 1) {
      history.push(process.env.PUBLIC_URL + "/home");
    }
  };

  const delFromCart = (id, all = false) => {
    if (all) {
      dispatch(remove_one_from_cart(id));
    } else {
      dispatch(remove_one_from_cart(id));
    }
    redirectToHomeIfEmpty(); // Redirige al home si el carrito queda vacío después de eliminar un producto
  }

  const peticionPost = async (values) => {
    dispatch(init_to_orden_compra(formik.values.orden_compra));
    dispatch(init_to_customer_note(formik.values.customer_note));
    //console.log(formik.values.orden_compra);
    history.push(process.env.PUBLIC_URL + "/direccion");
  }

  const cerrarEditar = () => {
    history.push(process.env.PUBLIC_URL + "/gracias");
  }

  useEffect(() => {

    if (cantidad == 0) {
      setVacio(false)
    }

  }, []);

  useEffect(() => {
    dispatch(init_to_prod(cart));
    dispatch(init_to_prod_quantity());
}, []);

  const clearCart = async (values) => {
    dispatch(clear_cart());
    history.push(process.env.PUBLIC_URL + "/home");
  }

  const guardarSinConfirmar = async (values) => {
    const response = await UserService.endPedidoSinConfirmar(values);
    ///setData(data.concat(response.data))
    if (values)
      dispatch(clear_cart());
    cerrarEditar()
  }

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" class="main">

        <div class="pagetitle">
          <h1>MI COMPRA</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Inicio</a></li>
            </ol>
          </nav>
        </div>

        {vacio && (
          <section class="section">
            <div class="row">
              <div class="col-lg-12">
                <div class="card top-selling overflow-auto">

                  <div class="card-body pb-0">
                    <h5 class="card-title">Carrito <span></span></h5>

                    <form onSubmit={formik.handleSubmit}>
                      <table class="table table-borderless" border="1">

                        <thead>
                          <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Descripción</th>
                            <th scope="col">Precio</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">SubTotal</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {cart.map((item) => (
                            <CartItem key={item.id} data={item} delFromCart={delFromCart} />
                          ))}
                          <Total data={cart} />
                        </tbody>
                      </table>

                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <TextField
                                name="orden_compra"
                                label="Orden de Compra"
                                value={formik.values.orden_compra}
                                onChange={formik.handleChange}
                                error={formik.touched.orden_compra && Boolean(formik.errors.orden_compra)}
                                helperText={formik.touched.orden_compra && formik.errors.orden_compra}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Podés agregar un comentario a tu pedido: </td>
                          </tr>
                          <tr>
                            <td>

                              <TextField
                                id="customer_note"
                                name="customer_note"
                                label="Comentario"
                                multiline
                                rows={4}
                                variant="outlined"
                                value={formik.values.customer_note}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.customer_note && Boolean(formik.errors.customer_note)}
                                helperText={formik.touched.customer_note && formik.errors.customer_note}
                                style={{ width: "500px" }} // ajustar el ancho del área de texto
                              />

                            </td>
                          </tr>
                          <tr>
                            <td>
                              <FooterText />
                            </td>
                          </tr>
                          <tr>
                            <td>
                            </td>
                            <td>
                              <table>
                                <tr>
                                  <td>
                                    <button onClick={() => clearCart()} class="btn btn-primary">LIMPIAR COMPRA</button>
                                  </td>
                                  <td>
                                    <button class="btn btn-primary">FINALIZAR COMPRA</button>
                                  </td>
                                  <td>
                                    <button onClick={() => guardarSinConfirmar(cart)} class="btn btn-primary">GUARDAR SIN CONFIRMAR</button>
                                  </td>
                                </tr>
                              </table>

                            </td>
                          </tr>
                        </tbody>


                      </table>
                    </form>

                  </div>

                </div>
              </div>
            </div>
          </section>
        )}
        {!vacio && (
          <>Carrito Vacio</>
        )}
      </main>
      <Footer />

    </>
  );
};

export default ShoppingCart;