import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clear_cart, init_to_min, init_to_dir, init_to_customer_note } from "../reducers/shoppingSlice";

import FrontEndHeader from "./FrontEnd_Header";
import FrontEndFooter from "./FrontEnd_Footer";
import FrontEndSidebar from "./FrontEnd_Sidebar";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const FrontEndGracias = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [currentUser, setCurrentUser] = useState({
        username: '',
    }); 

    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);

    useEffect(() => {

      const GetDirecciones = async () => {
        try {
  
          const result = await UserService.getDirecciones();
          if (result.data) {
   
            result.data.map(dir => {
              //dispatch(init_to_dir(dir.id));
              if (dir.predeterminada == 'true')
                dispatch(init_to_dir(dir.id));
            })
  
          } else {
            console.log('no hay datos')
          }
        } catch (e) {
          console.log(e);
        }
      }

      GetDirecciones();
  
      const GetMinimo = async () => {
        try {
  
          const result = await UserService.getMinimo();
          if (result.data) {
              dispatch(init_to_min(result.data));
          } else {
            console.log('no hay datos')
          }
        } catch (e) {
          console.log(e);
        }
      }
      GetMinimo();
  
    }, []);


    const inicio = () => {
        history.push(process.env.PUBLIC_URL + "/inicio");
    }

    return (
        
        <>
        <FrontEndHeader />
        <FrontEndSidebar />
        <main id="main" class="main">
  
          <div class="pagetitle">
            <h1>Gracias por tu pedido</h1>
          </div>
  
          <section class="section contact">
  
            <div class="row gy-4">
  
              <div class="col-xl-12">
  
                <div class="row">
  
                  <div class="col-lg-12">
                    <div class="info-box card">
                      <i class="ri-file-copy-line"></i>
                      <h3>Tu pedido..</h3>
                      <p></p>
                    </div>
                  </div>
                </div>
  
              </div>
            </div>
          </section>
  
        </main>
  
        <FrontEndFooter />
      </>


    );
}

export default FrontEndGracias;