import React, { useState, useEffect } from "react";
import { NumericFormat } from 'react-number-format';
import { useSelector, useDispatch } from "react-redux";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import { useHistory } from "react-router-dom";

const FrontEndTotal = ({ data }) => {
  //let { price, quantity } = data;

  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { cart, moneda, cantidad } = state;

  let order_total = 0;
  let total = 0;
  let bonificacion = 0;
  let bonificacion1 = 0;
  let bonificacion2 = 0;

  let order_total1 = 0;
  let order_total2 = 0;
  let order_total3 = 0;
  let order_total4 = 0;

  let iva = 0;
  let percepcion = 0;
  let ttemp = 0;

  const [bonificaciont, setBonificaciont] = useState(0);
  const [ivat, setIvat] = useState(0);
  const [percepciont, setPercepciont] = useState(0);
  const [ttempt, setTtempt] = useState(0);
  const [totalsiva, setTotalsiva] = useState(0);

  const [bbas, setBas] = useState(0);
  const [bgeneral, setBgeneral] = useState(0);
  const [bcliente, setBcliente] = useState(0);

  //data.map(function (bal) {
  cart.map(function (bal) {
    order_total = order_total + (bal.price * bal.quantity)
  });

  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }

    const GetData = async () => {
      try {
        const result = await UserService.getBonificaciones();

        if (result.data) {

          bonificacion = (order_total * result.data[0].extra_field_3) / 100; //BAS
          order_total1 = order_total - bonificacion;
          setBas(result.data[0].extra_field_3);

          bonificacion1 = (order_total1 * result.data[0].adecuacion) / 100;   //Descuento General
          order_total2 = order_total1 - bonificacion1;
          setBgeneral(result.data[0].adecuacion);

          bonificacion2 = (order_total2 * result.data[0].descuento) / 100;  //Descuento de cada cliente
          order_total3 = order_total2 - bonificacion2;
          bonificacion = bonificacion + bonificacion1 + bonificacion2;
          setBcliente(result.data[0].descuento);

          setBonificaciont(bonificacion);
          order_total4 = order_total1 + order_total2 + order_total3;
          iva = ((order_total - bonificacion) * result.data[0].extra_field_1) / 100;
          setIvat(iva);
          percepcion = ((order_total - bonificacion) * result.data[0].extra_field_2) / 100;
          setPercepciont(percepcion);
          total = order_total - bonificacion;
          setTotalsiva(total);
          ttemp = total + iva;
          setTtempt(ttemp);

        } else {
        }
      } catch (e) {
        console.log(e);
      }
    }
    GetData();


//  }, []);
}, [cart]); // Agrega cart como dependencia para que se ejecute cuando cambie el carrito

  return (
    <>
      <tr>
        <td colSpan={3}></td><td>SubTotal</td>
        <td>
          {moneda} <NumericFormat value={order_total} decimalScale={2} displayType={'text'} />
        </td>
      </tr>
      <tr>
        <td colSpan={3}></td>
        <td>
          {bbas !== 0 && (
            <>
              Bonificación <NumericFormat value={bbas} displayType="text" decimalScale={2} />%
            </>
          )}
          {bgeneral !== 0 && (
            <>
              +<NumericFormat value={bgeneral} displayType="text" decimalScale={2} />%
            </>
          )}
          {bcliente !== 0 && (
            <>
              +<NumericFormat value={bcliente} displayType="text" decimalScale={2} />%
            </>
          )}
        </td>
        <td>
          - {moneda} <NumericFormat value={bonificaciont} decimalScale={2} displayType={'text'} />
        </td>
      </tr>
      <tr>
        <td colSpan={3}></td><td>Total s/IVA</td>
        <td>
          {moneda} <NumericFormat value={totalsiva} decimalScale={2} displayType={'text'} />
        </td>
      </tr>
      <tr>
        <td colSpan={3}></td><td>Iva</td>
        <td>
          {moneda} <NumericFormat value={ivat} decimalScale={2} displayType={'text'} />
        </td>
      </tr>
      <tr>
        <td colSpan={3}></td><td>TOTAL</td>
        <td>
          {moneda} <NumericFormat value={ttempt} decimalScale={2} displayType={'text'} />
        </td>
      </tr>
    </>
  );
};

export default FrontEndTotal;