import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import { makeStyles } from '@mui/styles';

import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import AdminSidebar from "./Admin_Sidebar";

import Paper from '@mui/material/Paper';
import { Modal, Button } from '@mui/material';

import Breadcrumbs from '@mui/material/Breadcrumbs';

import Link from '@mui/material/Link';

import CircularProgress from '@mui/material/CircularProgress';

import {
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";

import MaterialTable from 'material-table';

let direction = "ltr";

const theme = createTheme(
    adaptV4Theme({
        direction: direction,
        palette: {
            mode: "light",
        },
    })
);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        position: 'absolute',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));

export default function AdminArticulos(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
            setShowAdminBoard(user.roles.includes("ROLE_ADMININSTRADORES"));
        }

        const GetData = async () => {
            try {
                //const result = await UserService.getArticulos();
                const result = await UserService.getArticulosList();
                if (result.data) {
                    //const res = JSON.parse(result);

                    //console.log(res);
                    setData(result.data);
                } else {
                    props.history.push("/login");
                }
            } catch (e) {
                console.log(e);
                props.history.push("/login");
            }
        }
        GetData();

    }, []);

    const update = async () => {
        setIsLoading(true);
        const response = await UserService.updateArticulos();
        if(response.data){
            setData(data.concat(response.data))
            setIsLoading(false);
        }
        //cerrarEditar()
    }

    return (
        <>
            <Header />
            <AdminSidebar />
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>Carousel</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li class="breadcrumb-item">Components</li>
                            <li class="breadcrumb-item active">Carousel</li>
                        </ol>
                    </nav>
                </div>

                <section class="section">
                    <div class="row">
                        <div class="col-lg-12">

                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Actualizar Articulos</h5>

                                    <Paper className={classes.root}>

                                    {isLoading && <CircularProgress color="secondary" />}

                                        <br />
                                        <Button style={{ color: "#fff", backgroundColor: "rgb(0, 79, 158)" }} variant="contained" onClick={() => update()}>Actualizar Articulos</Button>
                                        <br /><br />

                                        <StyledEngineProvider injectFirst>
                                            <ThemeProvider theme={theme}>
                                                <div style={{ maxWidth: "100%", direction }}>
                                                    <Grid container>
                                                        <Grid item xs={12}>

                                                            <MaterialTable
                                                                title=""

                                                                localization={{
                                                                    toolbar: {
                                                                        searchPlaceholder: 'Buscar...'
                                                                    },
                                                                    header: {
                                                                        actions: 'Acciones'
                                                                    },
                                                                }}

                                                                columns={[
                                                                    {
                                                                        title: 'Codigo',
                                                                        field: 'product_sku',
                                                                    },
                                                                    {
                                                                        title: 'Nombre',
                                                                        field: 'product_name',
                                                                    },
                                                                ]}
                                                                data={data}
                                                                options={{
                                                                    headerStyle: {
                                                                        backgroundColor: '#004F9E',
                                                                        color: '#FFF',
                                                                    },
                                                                    search: true,
                                                                    actionsColumnIndex: -1
                                                                }}
                                                            />

                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </ThemeProvider>
                                        </StyledEngineProvider>

                                    </Paper>


                                </div>
                            </div>

                        </div>

                    </div>
                </section>

            </main>
            <Footer />
        </>

    );
}

//export default Home;
