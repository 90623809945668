import React, { useState } from "react";

const FrontEndSidebar = ({ tipo }) => {

     return (

        <aside id="sidebar" class="sidebar">

            <ul class="sidebar-nav" id="sidebar-nav">

                <li class="nav-item">
                    <a class="nav-link collapsed" href="/">
                        <i class="bi bi-grid"></i>
                        <span>INICIO</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link collapsed" href="/categorias">
                        <i class="ri-shopping-cart-2-line"></i><span>REALIZAR PEDIDO</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#components-nav" href="/pedidoexpress">
                        <i class="ri-shopping-cart-2-line"></i><span>PEDIDO EXPRESS</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#components-nav" href="/shoppingcart">
                        <i class="ri-shopping-cart-2-line"></i><span>BUSCADOR</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#forms-nav" href="/historial">
                        <i class="bi bi-journal-text"></i><span>HISTORIAL DE PEDIDOS</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#forms-nav" href="/recuperar">
                        <i class="bi bi-journal-text"></i><span>RECUPERAR PEDIDOS</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#charts-nav" href="/listas">
                        <i class="bi bi-bar-chart"></i><span>LISTA DE PRECIOS</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#charts-nav" href="/catalogos">
                        <i class="bi bi-bar-chart"></i><span>CATALOGOS</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#charts-nav" href="/datosutiles">
                        <i class="bi bi-envelope"></i>
                        <span>DATOS UTILES</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link collapsed" data-bs-target="#charts-nav" href="/reclamos">
                        <i class="bi bi-envelope"></i>
                        <span>RECLAMOS/SUGERENCIAS</span> 
                    </a>
                </li>

                <a class="nav-link collapsed" href="https://industriabasica.ar" target="_blank">
                    <li class="nav-heading">INDUSTRIA BASICA S.A</li>
                </a>

            </ul>

        </aside>

    );
}
export default FrontEndSidebar;

