import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";

const FrontEndFooterText = ({ tipo }) => {

    const [data, setData] = useState([]);

    useEffect(() => {

        const GetTexto = async () => {
          try {
    
            const result = await UserService.getTexto();
            if (result.data) {
                setData(result.data.texto);
            } else {
              console.log('no hay datos')
            }
          } catch (e) {
            console.log(e);
          }
        }
        GetTexto();
   
      }, []);
    

    return (

        <tfoot>
            <tr>
                <td colspan="6"></td>
            </tr>
            <tr>
                <td colspan="6">{data}</td>
            </tr>
        </tfoot>

    );
}
export default FrontEndFooterText;

