import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";

import { makeStyles } from '@mui/styles';

import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import Sidebar from "./FrontEnd_Sidebar";
import { Modal, Button } from '@mui/material';
import { useDispatch } from "react-redux";
import { init_to_dir } from "../reducers/shoppingSlice";

import {
    Grid,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";
import { useFormik } from 'formik';
import * as yup from 'yup';

// Validacion del formulario
const validationSchema = yup.object({
    nota: yup
        .string('Nota requerida')
        .required('Nota requerida'),
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        position: 'absolute',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));

export default function Reclamos(props) {
    const formik = useFormik({
        initialValues: {
            nota: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const dispatch = useDispatch();
    const styles = useStyles();

    const [modalInsertar, setModalInsertar] = useState(false);

    const abrirCerrarModalInsertar = () => {
        setModalInsertar(!modalInsertar);
    }

    const peticionPost = async (values) => {
        await UserService.addNota(values.nota);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push("/graciasnota");
    }

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" class="main">
                <div class="pagetitle">
                    <h1>RECLAMOS / SUGERENCIAS</h1>
                </div>
                <section class="section profile">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-body pt-3">
                                    <section class="section">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                Queremos escucharte y conocer tus comentarios sobre nuestro sitio.
                                                Si tienes algún reclamo o sugerencia que pueda ayudarnos
                                                a hacer nuestro servicio aún mejor, te invitamos a compartirlo con nosotros!
                                                <br /><br />
                                            </div>
                                        </div>
                                    </section>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div><label for="nota" class="col-md-4 col-lg-3 col-form-label">Nota</label></div>
                                        <div class="row mb-3">
                                            
                                            <div class="col-md-8 col-lg-9">
                                                <textarea
                                                    name="nota"
                                                    id="nota"
                                                    rows="4"
                                                    class="form-control"
                                                    value={formik.values.nota}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                ></textarea>
                                                {formik.touched.nota && formik.errors.nota ? (
                                                    <div style={{ color: 'red' }}>{formik.errors.nota}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button type="submit" class="btn btn-primary">Enviar</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
