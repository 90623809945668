import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import AuthService from "../services/auth.service";

import { useHistory } from "react-router-dom";

const FrontEndCartNumero = () => {
    const state = useSelector((state) => state);
    const { cantidad } = state;

    const history = useHistory();

    const [currentUser, setCurrentUser] = useState({
        username: '',
    });

    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    let total = 0;
    const [numero, setNumero] = useState(0);

    useEffect(() => {

        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
            setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
            setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
        } else {
            history.push("/login");
        }

    }, []);

    return (
        <>
            <i class="bi bi-cart"></i>
            <span class="badge bg-primary badge-number">{cantidad}</span>
        </>
    );
}

export default FrontEndCartNumero;