import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import { makeStyles } from '@mui/styles';

import InputLabel from '@mui/material/InputLabel';
import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import AdminSidebar from "./Admin_Sidebar";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

import Paper from '@mui/material/Paper';
import { Modal, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";

import MaterialTable from 'material-table';

let direction = "ltr";

const theme = createTheme(
    adaptV4Theme({
        direction: direction,
        palette: {
            mode: "light",
        },
    })
);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        position: 'absolute',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));

//Validacion del formulario
const validationSchema = yup.object({
    minimo: yup
        .string('Minimo requerido')
        .required('Minimo requerido'),
});

//Validacion del formulario
const validationSchema1 = yup.object({
    adecuacion: yup
        .string('Minimo requerido')
        .required('Minimo requerido'),
});

export default function AdminDescuentos(props) {

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            minimo: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    //inicializacion de variables y validacion
    const formik1 = useFormik({
        initialValues: {
            adecuacion: '',
        },
        validationSchema: validationSchema1,
        onSubmit: (values) => {
            peticionPost1(values);
        },
    });

    const styles = useStyles();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [min, setMin] = useState(0);
    const [ade, setAde] = useState(0);
    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [consolaSeleccionada, setConsolaSeleccionada] = useState({
        id: '',
        nombre: '',
        codigo: '',
    })
    const [modalEliminar, setModalEliminar] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEditar1, setModalEditar1] = useState(false);

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
            setShowAdminBoard(user.roles.includes("ROLE_ADMININSTRADORES"));
        }

        const GetData = async () => {
            try {
                const result = await UserService.getClientes();
                if (result.data) {
                    //const res = JSON.parse(result.data);

                    console.log(result.data);
                    setData(result.data);
                } else {
                   // props.history.push("/login");
                }
            } catch (e) {
                console.log(e);
                props.history.push("/login");
            }
        }
        GetData();

        const GetMinimo = async () => {
            try {
                const result = await UserService.getMinimo();
                if (result.data[0].minimo) {
                    console.log(result.data[0].minimo)
                    setMin(result.data[0]);
                } else {
                   // props.history.push("/login");
                }
            } catch (e) {
                console.log(e);
                props.history.push("/login");
            }
        }
        GetMinimo();

        const GetAdecuacion = async () => {
            try {
                const result = await UserService.getAdecuacion();
                if (result.data[0].adecuacion) {
                    console.log(result.data[0].adecuacion)
                    setAde(result.data[0]);
                } else {
                   // props.history.push("/login");
                }
            } catch (e) {
                console.log(e);
                props.history.push("/login");
            }
        }
        GetAdecuacion();

    }, []);

    const update = async () => {
        try {
            const response = await UserService.updateClientes();
        } catch (e) {
            console.log(e);
        }
    }

    const seleccionarConsola = (consola, caso) => {
        setConsolaSeleccionada(consola);
        //console.log(consola.id);
        props.history.push(process.env.PUBLIC_URL + "/descuentosmod/" + consola.id)
    }

    const seleccionarConsola1 = (consola, caso) => {
        setConsolaSeleccionada(consola);
        (caso === 'Insertar' || caso === 'Editar') ? abrirCerrarModalEditar(consola.id)
            : abrirCerrarModalEliminar(consola.id)
    }

    const seleccionarConsola2 = (consola, caso) => {
        setConsolaSeleccionada(consola);
        (caso === 'Insertar' || caso === 'Editar') ? abrirCerrarModalEditar1(consola.id)
            : abrirCerrarModalEliminar1(consola.id)
    }

    const abrirCerrarModalEditar = (id) => {
        getMinimo(id);
    }

    const abrirCerrarModalEditar1 = (id) => {
        getAdecuacion(id);
    }


    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const abrirCerrarModalEliminar1 = () => {
        setModalEliminar(!modalEliminar);
    }

    const getMinimo = async (id) => {
        console.log(id)
        if (id) {
            const response = await UserService.getMinimoid(id);
            formik.setValues(response);
        } else {
            formik.resetForm();
        }
        setModalEditar(!modalEditar);
        //getDirecciones();
    }

    const getAdecuacion = async (id) => {
        console.log(id)
        if (id) {
            const response = await UserService.getAdecuacionid(id);
            formik1.setValues(response);
        } else {
            formik1.resetForm();
        }
        setModalEditar1(!modalEditar1);
        //getDirecciones();
    }

    const peticionPost = async (values) => {
        const response = await UserService.addmodMinimo(values.id, values);
        ///setData(data.concat(response.data))
        //if (values.predeterminada == 'true')
            //dispatch(init_to_dir(values.id));
            if(response){
                console.log(response.data)
                setMin(response.data[0]);
            }
            abrirCerrarModalEditar()
    }

    const peticionPost1 = async (values) => {
        const response = await UserService.addmodAdecuacion(values.id, values);
        ///setData(data.concat(response.data))
        //if (values.predeterminada == 'true')
            //dispatch(init_to_dir(values.id));
            if(response){
                console.log(response.data)
                setAde(response.data[0]);
            }
            abrirCerrarModalEditar1()
    }

    const bodyEditar = (
        <div className={styles.modal}>
            <h3>Minimo de Entrega</h3>

            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <TextField
                            name="minimo"
                            label="Minimo"
                            autoFocus={true}
                            required
                            value={formik.values.minimo}
                            onChange={formik.handleChange}
                            error={formik.touched.minimo && Boolean(formik.errors.minimo)}
                            helperText={formik.touched.minimo && formik.errors.minimo}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <div class="col-lg-12" style={{ textAlign: "right" }} >
                            <Button onClick={() => abrirCerrarModalEditar()}>Cancelar</Button>
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    )

    const bodyEditar1 = (
        <div className={styles.modal}>
            <h3>Adecuacion</h3>

            <form onSubmit={formik1.handleSubmit}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <TextField
                            name="adecuacion"
                            label="Adecuacion"
                            autoFocus={true}
                            required
                            value={formik1.values.adecuacion}
                            onChange={formik1.handleChange}
                            error={formik1.touched.adecuacion && Boolean(formik1.errors.adecuacion)}
                            helperText={formik1.touched.adecuacion && formik1.errors.adecuacion}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <div class="col-lg-12" style={{ textAlign: "right" }} >
                            <Button onClick={() => abrirCerrarModalEditar()}>Cancelar</Button>
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    )

    return (
        <>
            <Header />
            <AdminSidebar />
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>Descuentos</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Inicio</a></li>
                        </ol>
                    </nav>
                </div>

                <section class="section">
                    <div class="row">
                        <div class="col-lg-12">

                            <div class="text-left">
                                <button onClick={() => seleccionarConsola1(min, 'Insertar')} class="btn btn-primary">Minimo de Entrega [{min.minimo}]</button>
                                ---<button onClick={() => seleccionarConsola2(ade, 'Insertar')} class="btn btn-primary">Adecuacion [{ade.adecuacion}]</button>
                            </div>

                             <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Descuentos Clientes y Minimo de Entrega </h5>

                                    <Paper className={classes.root}>

                                        <StyledEngineProvider injectFirst>
                                            <ThemeProvider theme={theme}>
                                                <div style={{ maxWidth: "100%", direction }}>
                                                    <Grid container>
                                                        <Grid item xs={12}>

                                                            <MaterialTable
                                                                title=""

                                                                localization={{
                                                                    toolbar: {
                                                                        searchPlaceholder: 'Buscar...'
                                                                    },
                                                                    header: {
                                                                        actions: 'Acciones'
                                                                    },
                                                                }}
                                                                columns={[
                                                                    {
                                                                        title: 'Username',
                                                                        field: 'username',
                                                                    },
                                                                    {
                                                                        title: 'Name',
                                                                        field: 'name',
                                                                    },
                                                                    {
                                                                        title: 'Email',
                                                                        field: 'email',
                                                                    },
                                                                    {
                                                                        title: 'Descuento',
                                                                        field: 'descuento',
                                                                    },
                                                                ]}
                                                                actions={[
                                                                    {
                                                                        icon: 'edit',
                                                                        tooltip: 'Editar',
                                                                        onClick: (event, data) => seleccionarConsola(data, 'Editar'),
                                                                    },
                                                                ]}
                                                                data={data}
                                                                options={{
                                                                    headerStyle: {
                                                                        backgroundColor: '#004F9E',
                                                                        color: '#FFF',
                                                                    },
                                                                    search: true,
                                                                    actionsColumnIndex: -1
                                                                }}
                                                            />

                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </ThemeProvider>
                                        </StyledEngineProvider>

                                    </Paper>


                                </div>
                            </div>

                        </div>

                    </div>
                </section>

            </main>

            <Modal
                open={modalEditar}
                onClose={abrirCerrarModalEditar}>
                {bodyEditar}
            </Modal>

            <Modal
                open={modalEditar1}
                onClose={abrirCerrarModalEditar1}>
                {bodyEditar1}
            </Modal>
            <Footer />
        </>

    );
}

//export default Home;
