/**
 * PWA Ecommerce
 * update 10/2022
 * By Sergio Sam 
 */

import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import FrontEndHeader from "./FrontEnd_Header";
import FrontEndFooter from "./FrontEnd_Footer";
import FrontEndSidebar from "./FrontEnd_Sidebar";
import Alert from '@mui/material/Alert';

import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { init_to_dir, init_to_min } from "../reducers/shoppingSlice";

import { ServiceWorkerUpdateListener } from '../ServiceWorkerUpdateListener.js'

import ResponsiveImage from './FrontEnd_ResponsiveImage';
import './ResponsiveImage.css'; // Importa el archivo CSS para el contenedor de imágenes


const FrondEndHome = () => {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [swListener, setSwListener] = useState({});
  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [registration, setRegistration] = useState(null);

  const dispatch = useDispatch();

  const [bbas, setBbas] = useState('');
  const [bgeneral, setBgeneral] = useState('');
  const [bcliente, setBcliente] = useState('');

  useEffect(() => {

    const GetDirecciones = async () => {
      try {

        const result = await UserService.getDirecciones();
        if (result.data) {

          result.data.map(dir => {
            //dispatch(init_to_dir(dir.id));
            if (dir.predeterminada == 'true')
              dispatch(init_to_dir(dir.id));
          })

        } else {
          console.log('no hay datos')
        }
      } catch (e) {
        console.log(e);
      }
    }
    GetDirecciones();

    const GetMinimo = async () => {
      try {

        const result = await UserService.getMinimo();
        if (result.data) {
          dispatch(init_to_min(result.data));
        } else {
          console.log('no hay datos')
        }
      } catch (e) {
        console.log(e);
      }
    }
    GetMinimo();

    const GetDescuento = async () => {
      try {

        const result = await UserService.getBonificaciones();
        if (result.data) {
          const bbasValue = parseFloat(result.data[0].extra_field_3);
          setBbas(bbasValue.toFixed(2));  //BAS

          const bgeneralValue = parseFloat(result.data[0].adecuacion);
          setBgeneral(bgeneralValue.toFixed(2)); // 1;   //Descuento General

          const bclienteValue = parseFloat(result.data[0].descuento);
          setBcliente(bclienteValue.toFixed(2));    //Descuento de cada cliente
        } else {
          console.log('no hay datos')
        }
      } catch (e) {
        console.log(e);
      }
    }
    GetDescuento();

    if (process.env.NODE_ENV !== "development") {
      let listener = new ServiceWorkerUpdateListener();
      setSwListener(listener);
      listener.onupdateinstalling = (installingEvent) => {
        console.log("SW installed", installingEvent);
      };
      listener.onupdatewaiting = (waitingEvent) => {
        console.log("New update waiting", waitingEvent);
        setUpdateWaiting(true);
      };
      listener.onupdateready = (event) => {
        console.log("Updateready event");
        window.location.reload();
      };
      navigator.serviceWorker.getRegistration().then((reg) => {
        listener.addRegistration(reg);
        setRegistration(reg);
      });

      return () => listener.removeEventListener(null, null);
    } else {
      //do nothing because no sw in development
    }
  }, []);

  const handleUpdate = () => {
    swListener.skipWaiting(registration.waiting);
  }

  const [open, setOpen] = React.useState(false);

  return (

    <body>

      <FrontEndHeader />
      <FrontEndSidebar />
      <main id="main" class="main">

        <UpdateWaiting updateWaiting={updateWaiting} handleUpdate={handleUpdate} />

        <div class="pagetitle">
          <h1>Bienvenido al Sitio Exclusivo para Distribuidores</h1>
        </div>

        <section class="section">
          <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">

                  <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <ResponsiveImage src="https://api.nuban.ar/uploads/noticia1.jpg" alt="Descripción de la imagen 1" />
                      </div>
                      <div class="carousel-item">
                        <ResponsiveImage src="https://api.nuban.ar/uploads/noticia2.jpg" alt="Descripción de la imagen 1" />
                      </div>
                      <div class="carousel-item">
                        <ResponsiveImage src="https://api.nuban.ar/uploads/noticia3.jpg" alt="Descripción de la imagen 1" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>
        </section>

        <section class="section dashboard">
          <div class="row">

            <div class="col-lg-4">
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Mi Bonificacion Base + Web</h5>
                  <div class="d-flex align-items-center">
                    <div class="ps-3">
                      <h6>{bbas !== 0 ? bbas : ' '}%</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title">Adecuacion</h5>
                  <div class="d-flex align-items-center">
                    <div class="ps-3">
                      <h6>{bgeneral !== 0 ? bgeneral : ' '}%</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {bcliente !== '0.00' && (
              <div class="col-lg-4">
                <div class="card info-card sales-card">
                  <div class="card-body">
                    <h5 class="card-title">Descuento Extra</h5>
                    <div class="d-flex align-items-center">
                      <div class="ps-3">
                        <h6>{bcliente !== 0 ? bcliente : ' '}%</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        </section>

      </main>

      <FrontEndFooter />

    </body>

  );
};

export default FrondEndHome;

const UpdateWaiting = ({ updateWaiting, handleUpdate }) => {
  if (!updateWaiting) return <></>
  return (
    <Alert variant="filled" severity="error" onClick={handleUpdate}>
      Versión nueva disponible, Pulsa aquí para Actualizar!
    </Alert>
  )
}