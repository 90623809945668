import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";

//const MenuSubCategorias = (props) => {
const FrontEndMenuSubCategorias = ({ tipo }) => {
    const [data, setData] = useState([]);

    useEffect(() => {

        const GetData = async () => {
            try {
                //Busca en category_xref todas las que tengan category_parent_id == 0
                const result = await UserService.getSubcategorias(tipo);
                if (result.data) {
 
                    let dato = result.data.map(consola => {
                        console.log(consola)
                        return (
                            <div class="card mb-3">
                                <div class="row g-0">
                                    <div class="col-md-4">
                                    </div>
                                    <div class="col-md-8">
                                        <div class="card-body">
                                            <h5 class="card-title"><a href={`${process.env.PUBLIC_URL}/articulos/`+`${consola.cat_child_id}`}> {consola.name}</a></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        );
                    });
                    setData(dato);
                } else {
                    //props.history.push("/login");
                }
            } catch (e) {
                console.log(e);
                //props.history.push("/login");
            }
        }
        GetData();


    }, []);

    return (
        <>
            {data}
        </>
    );
}
export default FrontEndMenuSubCategorias;

