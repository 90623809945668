import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { add_to_cart_quantity } from "../reducers/shoppingSlice";

import { useHistory } from "react-router-dom";

const ProductItemHistorial = ({ data, addToCart }) => {

  const history = useHistory();

  let { id, number, fecha, total } = data;

  //let quantity = data.quantity.quantity;

   
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { moneda } = state;

  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/img/sinimagen.png";
  }

  const handleChange = e => {
    const { name, value, id } = e.target;
    dispatch(add_to_cart_quantity({ id: id, quantity: value }))
  }

  const abrirCerrarModalEditar = () => {
    history.push(process.env.PUBLIC_URL + "/carthistorial/"+id)
  }

  return (
    <tr>
      <td scope="row">
        {number}
      </td>
      <td>{fecha}</td>
      <td>{moneda} {total}</td>
      <td><p onClick={() => abrirCerrarModalEditar(id)} >+</p></td>
    </tr>
  );
};

export default ProductItemHistorial;