import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";


const FrontEndMenuCategorias0 = ({ cat }) => {
    //let { id, name, price } = data;
    const [data, setData] = useState([]);

    useEffect(() => {

    }, []);

    return (
        <>

            <div class="col-lg-4">
                <a class="nav-link collapsed" data-bs-target="#components-nav" href="/categoriaprincipal/Perforada">
                    <div class="card">
                        <img src="/assets/img/nuban_clip.jpg" class="img-fluid rounded-start" alt="consola.name" />
                        <div class="card-body">
                            <h5 class="xcard-title">Bandejas Portacables<br/> Fondo Perforado</h5>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-lg-4">
                <a class="nav-link collapsed" data-bs-target="#components-nav" href="/categoriaprincipal/Nuban Clinch">
                    <div class="card">
                        <img src="/assets/img/nuban_clinch.jpg" class="img-fluid rounded-start" alt="consola.name" />
                        <div class="card-body">
                        <h5 class="xcard-title">Bandejas Portacables<br/> Clinchadas tipo Escalera</h5>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-lg-4">
                <a class="nav-link collapsed" data-bs-target="#components-nav" href="/categoriaprincipal/Escalera">
                    <div class="card">
                        <img src="/assets/img/nuban_bpc.jpg" class="img-fluid rounded-start" alt="consola.name" />
                        <div class="card-body">
                        <h5 class="xcard-title">Bandejas Portacables<br/> tipo Escalera</h5>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-lg-4">
                <a class="nav-link collapsed" data-bs-target="#components-nav" href="/categoriaprincipal/Nuban Up">
                    <div class="card">
                        <img src="/assets/img/nuban_up.jpg" class="img-fluid rounded-start" alt="consola.name" />
                        <div class="card-body">
                        <h5 class="xcard-title">Bandejas Portacables<br/> tipo Escalera Línea Ultra Pesada</h5>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-lg-4">
                <a class="nav-link collapsed" data-bs-target="#components-nav" href="/categoriaprincipal/Accesorios">
                    <div class="card">
                        <img src="/assets/img/nuban_soportes.jpg" class="img-fluid rounded-start" alt="consola.name" />
                        <div class="card-body">
                        <h5 class="xcard-title">Elementos de Sujeción<br/> y Soporte</h5>
                        </div>
                    </div>
                </a>
            </div>

        </>
    );
}
export default FrontEndMenuCategorias0;

