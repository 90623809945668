import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FrontEndHeader from "./FrontEnd_Header";
import FrontEndFooter from "./FrontEnd_Footer";
import FrontEndSidebar from "./FrontEnd_Sidebar";

const FrontEndAyuda = () => {
    const history = useHistory();

    const [currentUser, setCurrentUser] = useState({
        username: '',
    });

    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);

    useEffect(() => {

    }, []);


    const inicio = () => {
        history.push(process.env.PUBLIC_URL + "/inicio");
    }

    return (

        <>
            <FrontEndHeader />
            <FrontEndSidebar />
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>PREGUNTAS FRECUENTES</h1>
                </div>

                <section class="section contact">

                    <div class="row gy-4">

                        <div class="col-xl-12">

                            <div class="row">

                                <div class="col-lg-12">
                                    <div class="info-box card">
                                        <i class="ri-file-copy-line"></i>
                                        <h3></h3>
                                        <div>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography> ¿Cómo modifico mi clave y usuario web? </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        La clave y el usuario web se debe solicitar a Administración comercial exclusivamente a través de su  vendedor.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography> ¿Como puedo ver la lista de precios vigente?  </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Puede bajar la lista vigente desde el margen izquierdo seleccionando: Lista de precios, la misma esta en excel y en pdf.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography> ¿Puedo modificar el mail de recepción de comprobantes?  </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Si, se puede modificar, lo debe solicitar a Administración comercial exclusivamente a través de su  vendedor.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography> ¿Cómo descargo los comprobantes ?  </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Lo puede descargar del margen derecho superior desde su perfil
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>  ¿Con qué tipo de cambio se realiza la factura de mi pedido? </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Su pedido se realiza con el tc del banco Nación - cotización divisa del día anterior.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>  ¿Cuándo me corresponde el descuento por pronto pago? </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        El descuento de pronto pago corresponde cuando la factura es abonada dentro de las 72 hs.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>   ¿Cuánto demoro en recibir mi pedido? </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Su pedido será despachado dentro de las 72 hs habiles una vez remitado el mismo.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <p></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </main>

            <FrontEndFooter />
        </>


    );
}

export default FrontEndAyuda;