import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";

const FrontEndMenuCategorias = (props) => {

    const [data, setData] = useState([]);

    useEffect(() => {

        const GetData = async () => {
            try {
                //Busca en category_xref todas las que tengan category_parent_id == 0
                const result = await UserService.getCategorias();
                if (result.data) {

                    let dato = result.data.map(consola => {
                        return (
                            <div class="card mb-3">
                                <div class="row g-0">
                                    <div class="col-md-4">
                                        <img src={`/assets/img/` + `${consola.name}`+".jpg"} class="img-fluid rounded-start" alt={`${consola.name}`} />
                                    </div>
                                    <div class="col-md-8">
                                        <div class="card-body">
                                            <h5 class="card-title">{consola.name}</h5>

                                            {consola.hija.map((dataItem) => {
                                                return (
                                                    <div><a href={`${process.env.PUBLIC_URL}/pedido1/` + `${dataItem.id}`}> {dataItem.name}</a></div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        );
                    });
                    setData(dato);
                } else {
                    props.history.push("/login");
                }
            } catch (e) {
                console.log(e);
                props.history.push("/login");
            }
        }
        GetData();


    }, []);

    return (
        <>
            {data}
        </>
    );
}
export default FrontEndMenuCategorias;

