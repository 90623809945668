import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";

import { makeStyles } from '@mui/styles';

import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import AdminSidebar from "./Admin_Sidebar";
import AuthService from "../services/auth.service";
import TextField from '@mui/material/TextField';
import { Modal, Button } from '@mui/material';
import { useParams } from 'react-router';

import {
    Grid,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";
import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
    descuento: yup
        .string('Descuento requerido')
        .required('Descuento requerido'),
});

let direction = "ltr";

const theme = createTheme(
    adaptV4Theme({
        direction: direction,
        palette: {
            mode: "light",
        },
    })
);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        position: 'absolute',
        width: 400,
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    }
}));

export default function AdminDescuentosMod(props) {

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            descuento: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
            //alert(JSON.stringify(values, null, 2));
        },
    });

    const styles = useStyles();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [showClienteBoard, setShowClienteBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [consolaSeleccionada, setConsolaSeleccionada] = useState({
        id: '',
        nombre: '',
        codigo: '',
    })
    const { id } = useParams();

    const seleccionarConsola = (consola, caso) => {
        setConsolaSeleccionada(consola);
        //console.log(consola.id);
        (caso === 'Editar') ? props.history.push(process.env.PUBLIC_URL + "/vehiculosmod/" + consola.id) : abrirCerrarModalEliminar()
    }
    const abrirCerrarModalEliminar = () => {
        setModalEliminar(!modalEliminar);
    }

    const peticionDelete = async () => {
        const response = await UserService.delTexto(consolaSeleccionada.id);
        var data = response.data;
        peticionGet();
        abrirCerrarModalEliminar();
    }

    const peticionGet = async () => {
        const result = await UserService.getTextos();
        setData(result.data);
    }

    useEffect(() => {

        const user = AuthService.getCurrentUser();
        if (user) {
            setShowClienteBoard(user.roles.includes("ROLE_CLIENTE"));
            setShowAdminBoard(user.roles.includes("ROLE_ADMINISTRADORES") || user.roles.includes("ROLE_MASTER"));
        } else {
            props.history.push("/login");
        }

        const GetData = async () => {
            try {
                const response = await UserService.getDescuento(id);

                if (response) {
                    formik.setValues(response);
                    //response.data.map(consola => {
                                        
                        //formik.initialValues.descuento = consola.descuentos;
                    //});
                } else {
                    props.history.push(process.env.PUBLIC_URL + "/login");
                }
            } catch (e) {
                props.history.push(process.env.PUBLIC_URL + "/login");
            }
        }
        GetData();
    }, []);

    const inicio = async () => {
        props.history.push("/");
    }

    const peticionPost = async (values) => {
        const response = await UserService.addmodDescuento(id,values);
        ///setData(data.concat(response.data))
         cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/admindescuentos");
        //peticionGet();

    }

    const bodyEliminar = (
        <div className={styles.modal}>
            <p>Estás seguro que deseas eliminar el Texto <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
            <div align="right">
                <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
                <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
            </div>
        </div>
    )

    return (
        <>
            <Header />
            <AdminSidebar />
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>Descuento por Cliente</h1>
                </div>

                <section class="section profile">
                    <div class="row">

                        <div class="col-xl-12">

                            <div class="card">
                                <div class="card-body pt-3">

                                    <ul class="nav nav-tabs nav-tabs-bordered">

                                        <li class="nav-item">
                                            Descuento a aplicar
                                        </li>

                                    </ul>
                                    <div class="tab-content pt-2">

                                        <div class="tab-pane show active fade pt-3" id="profile-settings">

                                            <div class="row">

                                                <form onSubmit={formik.handleSubmit}>
                                                    <Grid container spacing={3}>

                                                    <Grid item xs={12}>
                                                            <TextField
                                                                name="descuento"
                                                                label="Descuento"
                                                                autoFocus={true}
                                                                required
                                                                value={formik.values.descuento}
                                                                onChange={formik.handleChange}
                                                                error={formik.touched.descuento && Boolean(formik.errors.descuento)}
                                                                helperText={formik.touched.descuento && formik.errors.descuento}
                                                            />
                                                        </Grid>

                                                        <div class="col-lg-12" style={{ textAlign: "right" }} >
                                                            <br />
                                                        </div>

                                                        <div class="col-lg-12" style={{ textAlign: "right" }} >
                                                            <button type="submit" class="btn btn-primary">Guardar</button>
                                                        </div>
                                                    </Grid>
                                                </form>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </main>
            <Modal
                open={modalEliminar}
                onClose={abrirCerrarModalEliminar}>
                {bodyEliminar}
            </Modal>
            <Footer />
        </>

    );
}

//export default Home;
