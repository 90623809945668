import React, { useState, useEffect } from "react";
import DownloadLink from './DownloadLink';

const FrontEndListasDePrecios = ({ tipo }) => {
    const [fileError, setFileError] = useState(false);
    const handleFileError = () => {
        setFileError(true);
    };
    return (
        <>
            <br />
            <br />
            <div class="card mb-3">
                <div class="row g-0">
                    <div class="col-md-2">
                        <img src="assets/img/lista_nuban_xls.jpg" class="img-fluid rounded-start" alt="..." />
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <h5 class="card-title">Lista de precios NUBAN <span>(Con y sin placas incluidas)</span></h5>
                            <p class="card-text">Formato Excel.</p>
                            <DownloadLink url="https://api.nuban.ar/uploads/lis_bpc.xlsx" fileName="lis_bpc.xlsx" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="row g-0">
                    <div class="col-md-2">
                        <img src="assets/img/lista_nuban_pdf.jpg" class="img-fluid rounded-start" alt="..." />
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <h5 class="card-title">Lista de precios NUBAN</h5>
                            <p class="card-text">Formato PDF.</p>
                            <DownloadLink url="api.nuban.ar/uploads/cat_nuban_pdf.pdf" fileName="lis_bpc.xlsx" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="row g-0">
                    <div class="col-md-2">
                        <img src="assets/img/lista_nuban_tramos_pdf.jpg" class="img-fluid rounded-start" alt="..." />
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <h5 class="card-title">Lista de precios NUBAN <span>(SOLO TRAMOS)</span></h5>
                            <p class="card-text">Formato PDF.</p>
                            <DownloadLink url="https://api.nuban.ar/uploads/lis_nuban_up.pdf" fileName="lis_bpc.xlsx" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default FrontEndListasDePrecios;

