import React, { useState, useEffect } from 'react';
import UserService from "../services/user.service";

function DownloadLink({ url, fileName }) {
  const [fileExists, setFileExists] = useState(false);
  const [urlConVersion, setUrlConVersion] = useState("");

  useEffect(() => {

    const checkFileExists = async () => {
      try {

        const result = await UserService.checkFile(url);
        if (result.exists) {
          setFileExists(result.exists);
          // Añadimos un parámetro único a la URL (cache busting)
          const timestamp = new Date().getTime();  // Genera un timestamp único
          setUrlConVersion(`${url}?v=${timestamp}`);  // Adjunta el timestamp a la URL
        } else {
          setFileExists(false);
        }
      } catch (e) {
        setFileExists(false);
      }
    }
    checkFileExists();

  }, [url]);

  return (
    <div>
      {fileExists ? (
        <a href={urlConVersion} target="_blank" rel="noopener noreferrer">
          <button type="button" className="btn btn-outline-primary">
            <i className="ri-download-2-line"></i> Descargar
          </button>
        </a>
      ) : (
        <button type="button" className="btn btn-outline-primary" disabled>
          <i className="ri-download-2-line"></i> {fileName} no disponible
        </button>
      )}
    </div>
  );
}

export default DownloadLink;
