import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";

import { makeStyles } from '@mui/styles';

import InputLabel from '@mui/material/InputLabel';
import Header from "./FrontEnd_Header";
import Footer from "./FrontEnd_Footer";
import Sidebar from "./FrontEnd_Sidebar";
import AuthService from "../services/auth.service";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { Modal, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from "react-redux";
import { init_to_dir } from "../reducers/shoppingSlice";

import {
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";
import { useFormik } from 'formik';
import * as yup from 'yup';
import MaterialTable from 'material-table';

//Validacion del formulario
const validationSchema = yup.object({
  direccion: yup
    .string('Direccion requerido')
    .required('Direcicon requerido'),
  codigopostal: yup
    .string('Codigo Postal requerido')
    //.min(8, 'Password should be of minimum 8 characters length')
    .required('Codigo Postal requerido'),
  localidad: yup
    .string('Localidad requerido ')
    //.min(8, 'Password should be of minimum 8 characters length')
    .required('Localida requerido'),
  predeterminada: yup
    .string('Localidad requerido ')
    //.min(8, 'Password should be of minimum 8 characters length')
    .required('Localida requerido'),
});

let direction = "ltr";

const theme = createTheme(
  adaptV4Theme({
    direction: direction,
    palette: {
      mode: "light",
    },
  })
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: 5,
    padding: (2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconos: {
    cursor: 'pointer'
  },
  inputMaterial: {
    width: '100%'
  }
}));

export default function Profile(props) {

  //inicializacion de variables y validacion
  const formik = useFormik({
    initialValues: {
      direccion: '',
      codigopostal: '',
      localidad: '',
      provincia: '',
      contacto: '',
      destinofinal: '',
      predeterminada: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      peticionPost(values);
    },
  });

  const dispatch = useDispatch();

  const styles = useStyles();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [dataArchivos, setDataArchivos] = useState([]);
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    id: '',
    nombre: '',
    codigo: '',
  })

  const [isLoading, setIsLoading] = useState(true);

  const [predeterminada, setPredeterminada] = useState([
    { id: 'true', nombre: 'Si' },
    { id: 'false', nombre: 'No' },
  ]);

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Insertar' || caso === 'Editar') ? abrirCerrarModalEditar(consola.id)
      : abrirCerrarModalEliminar(consola.id)
  }

  const seleccionarConsolaArchivos = async (consola, caso) => {
    //console.log(consola)
    const response = await UserService.getPdf(consola.Nombre);
    var data = response.data;

  }

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  }

  const abrirCerrarModalEditar = (id) => {
    getDireccion(id);
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const getDireccion = async (id) => {
    if(id){
      const response = await UserService.getDireccionid(id);
      formik.setValues(response);
    }else{
      formik.resetForm();
    }
    setModalEditar(!modalEditar);
    getDirecciones();
  }

  const peticionDelete = async () => {
    const response = await UserService.delDireccion(consolaSeleccionada.id);
    var data = response.data;
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const peticionGet = async () => {
    const result = await UserService.getDirecciones();
    setData(result.data);
  }

  //trae lista de direcciones
  const getDirecciones = async () => {
    const result = await UserService.getDirecciones();
    setData(result.data);
  }

  useEffect(() => {

    const GetData = async () => {
      try {
        const response = await UserService.getDirecciones();

        if (response) {
          var dataNueva = response.data;
          setData(dataNueva);
          setIsLoading(false);
        } else {
        }
      } catch (e) {
      }
    }
    GetData();

  const GetArchivos = async () => {
    try {
      const response = await UserService.getListadoDeArchivos();

      if (response) {
        var dataNueva = response.data;
        setDataArchivos(dataNueva);
        setIsLoading(false);
      } else {
      }
    } catch (e) {
      console.log(e)
    }
  }
  GetArchivos();
}, []);


  const inicio = async () => {
    props.history.push("/");
  }

  const peticionPost = async (values) => {
    const response = await UserService.addmodDireccion(values.id, values);
    ///setData(data.concat(response.data))
    if(values.predeterminada=='true')
      dispatch(init_to_dir(values.id));
    abrirCerrarModalEditar()
  }

  const cerrarEditar = () => {
    //props.history.push(process.env.PUBLIC_URL + "/profile");
    peticionGet();

  }

  const bodyEditar = (
    <div className={styles.modal}>
      <h3>Datos de la Direccion</h3>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>

          <Grid item xs={6}>
            <TextField
              name="direccion"
              label="Direccion"
              autoFocus={true}
              required
              value={formik.values.direccion}
              onChange={formik.handleChange}
              error={formik.touched.direccion && Boolean(formik.errors.direccion)}
              helperText={formik.touched.direccion && formik.errors.direccion}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="codigopostal"
              label="Codigo Postal"
              required
              value={formik.values.codigopostal}
              onChange={formik.handleChange}
              error={formik.touched.codigopostal && Boolean(formik.errors.codigopostal)}
              helperText={formik.touched.codigopostal && formik.errors.codigopostal}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="localidad"
              label="Localidad"
              required
              value={formik.values.localidad}
              onChange={formik.handleChange}
              error={formik.touched.localidad && Boolean(formik.errors.localidad)}
              helperText={formik.touched.localidad && formik.errors.localidad}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="provincia"
              label="Provincia"
              required
              value={formik.values.provincia}
              onChange={formik.handleChange}
              error={formik.touched.provincia && Boolean(formik.errors.provincia)}
              helperText={formik.touched.provincia && formik.errors.provincia}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="contacto"
              label="Contacto"
              value={formik.values.contacto}
              onChange={formik.handleChange}
              error={formik.touched.contacto && Boolean(formik.errors.contacto)}
              helperText={formik.touched.contacto && formik.errors.contacto}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="destinofinal"
              label="Destino Final"
              value={formik.values.destinofinal}
              onChange={formik.handleChange}
              error={formik.touched.destinofinal && Boolean(formik.errors.destinofinal)}
              helperText={formik.touched.destinofinal && formik.errors.destinofinal}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel htmlFor="outlined-age-native-simple">Predeterminada</InputLabel>
            <Select
              required
              native
              label="Predeterminada"
              value={formik.values.predeterminada}
              onChange={formik.handleChange}
              error={formik.touched.predeterminada && Boolean(formik.errors.predeterminada)}
              helperText={formik.touched.predeterminada && formik.errors.predeterminada}
              inputProps={{
                name: 'predeterminada',
                id: 'outlined-age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              {predeterminada.map((value) => (
                <option value={value.id} key={value.id}>
                  {value.nombre}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <div class="col-lg-12" style={{ textAlign: "right" }} >
              <Button onClick={() => abrirCerrarModalEditar()}>Cancelar</Button>
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </Grid>

        </Grid>
      </form>
    </div>
  )

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar la Direccion <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" class="main">

        <div class="pagetitle">
          <h1>PERFIL DE USUARIO</h1>
        </div>

        <section class="section profile">
          <div class="row">

            <div class="col-xl-12">

              <div class="card">
                <div class="card-body pt-3">

                  <ul class="nav nav-tabs nav-tabs-bordered">

                    <li class="nav-item">
                      <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Direcciones de entrega</button>
                    </li>

                    <li class="nav-item">
                      <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Mis datos</button>
                    </li>

                    <li class="nav-item">
                      <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-settings">Comprobantes</button>
                    </li>

                  </ul> 
                  <div class="tab-content pt-2">

                    <div class="tab-pane fade show active profile-overview" id="profile-overview">
                      <h5 class="card-title">Direcciones</h5>

                      <div class="row mb-12">

                        <div class="text-left">
                          <button onClick={() => seleccionarConsola(data, 'Insertar') } class="btn btn-primary">Nueva Direccion</button>
                        </div>
                        
                        <StyledEngineProvider injectFirst>
                          <ThemeProvider theme={theme}>
                            <div style={{ maxWidth: "100%", direction }}>
                              <Grid container>
                                <Grid item xs={12}>

                                  {isLoading && <CircularProgress color="secondary" />}
                                  <MaterialTable
                                    title=""

                                    localization={{
                                      toolbar: {
                                        searchPlaceholder: 'Buscar...'
                                      },
                                      header: {
                                        actions: 'Acciones'
                                      },
                                    }}

                                    columns={[
                                      {
                                        title: 'Direccion',
                                        field: 'direccion',
                                      },
                                      {
                                        title: 'Codigo Postal',
                                        field: 'codigopostal',
                                      },
                                      {
                                        title: 'Localidad',
                                        field: 'localidad',
                                      },
                                      {
                                        title: 'Provincia',
                                        field: 'provincia',
                                      },
                                      {
                                        title: 'Contacto',
                                        field: 'contacto',
                                      },
                                      {
                                        title: 'Destino Final',
                                        field: 'destinofinal',
                                      },
                                    ]}
                                    actions={[
                                      {
                                        icon: 'edit',
                                        tooltip: 'Editar',
                                        onClick: (event, data) => seleccionarConsola(data, 'Editar'),
                                      },
                                      {
                                        icon: 'delete',
                                        tooltip: 'Eliminar',
                                        onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
                                      }
                                    ]}
                                    data={data}
                                    options={{
                                      headerStyle: {
                                        backgroundColor: '#ec601c',
                                        color: '#FFF',
                                      },
                                      search: true,
                                      actionsColumnIndex: -1
                                    }}
                                  />

                                </Grid>
                              </Grid>
                            </div>
                          </ThemeProvider>
                        </StyledEngineProvider>

                      </div>

                    </div>

                    <div class="tab-pane fade profile-edit pt-3" id="profile-edit">

                      <form>

                        <div class="row mb-3">
                          <label for="fullName" class="col-md-4 col-lg-3 col-form-label">Nombre</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="fullName" type="text" class="form-control" id="fullName" value="" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="company" class="col-md-4 col-lg-3 col-form-label">Empresa</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="company" type="text" class="form-control" id="company" value="" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="Phone" class="col-md-4 col-lg-3 col-form-label">Telefono</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="phone" type="text" class="form-control" id="Phone" value="" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="Email" class="col-md-4 col-lg-3 col-form-label">Email</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="email" type="email" class="form-control" id="Email" value="" />
                          </div>
                        </div>

                        <div class="text-center">
                          <button type="submit" class="btn btn-primary">Enviar</button>
                        </div>
                      </form>

                    </div>

                    <div class="tab-pane fade pt-3" id="profile-settings">

                      <h5 class="card-title">Comprobantes</h5>

                      <div class="row mb-12">

                        <StyledEngineProvider injectFirst>
                          <ThemeProvider theme={theme}>
                            <div style={{ maxWidth: "100%", direction }}>
                              <Grid container>
                                <Grid item xs={12}>

                                  <MaterialTable
                                    title=""

                                    localization={{
                                      toolbar: {
                                        searchPlaceholder: 'Buscar...'
                                      },
                                      header: {
                                        actions: 'Acciones'
                                      },
                                    }}

                                    columns={[
                                      {
                                        title: 'Comprobante',
                                        field: 'Nombre',
                                      },
                                      {
                                        title: 'Fecha',
                                        field: 'Modificado',
                                      },
                                    ]}
                                    actions={[
                                      {
                                        icon: 'cloud_download', // Cambiar a 'cloud_download' para el ícono de descargas
                                        tooltip: 'Descargar',
                                        onClick: (event, dataArchivos) => seleccionarConsolaArchivos(dataArchivos, 'Editar'),
                                      },
                                    ]}
                                    data={dataArchivos}
                                    options={{
                                      headerStyle: {
                                        backgroundColor: '#ec601c',
                                        color: '#FFF',
                                      },
                                      search: true,
                                      actionsColumnIndex: -1
                                    }}
                                  />

                                </Grid>
                              </Grid>
                            </div>
                          </ThemeProvider>
                        </StyledEngineProvider>

                      </div>
                    </div>

                    <div class="tab-pane fade pt-3" id="profile-change-password">
                      <form>

                        <div class="row mb-3">
                          <label for="currentPassword" class="col-md-4 col-lg-3 col-form-label">Clave actual</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="password" type="password" class="form-control" id="currentPassword" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="newPassword" class="col-md-4 col-lg-3 col-form-label">Nueva clave</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="newpassword" type="password" class="form-control" id="newPassword" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="renewPassword" class="col-md-4 col-lg-3 col-form-label">Repita la clave</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="renewpassword" type="password" class="form-control" id="renewPassword" />
                          </div>
                        </div>

                        <div class="text-center">
                          <button type="submit" class="btn btn-primary">Cambiar Clave</button>
                        </div>
                      </form>

                    </div>

                  </div>

                </div>
              </div>

            </div>
          </div>
        </section>

      </main>

      <Modal
        open={modalEditar}
        onClose={abrirCerrarModalEditar}>
        {bodyEditar}
      </Modal>

      <Modal
        open={modalEliminar}
        onClose={abrirCerrarModalEliminar}>
        {bodyEliminar}
      </Modal>

      <Footer />
    </>

  );
}

//export default Home;
